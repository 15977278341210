import React from 'react';

import BackgroundPage from '../../components/BackgroundPage/';
import UploadScansPopup from '../../components/UploadScansPopup/';
import Table from './components/Table/';

import ScreeningsBG from '../../assets/img/Screenings.jpg';

import './index.scss';

const Screenings = () => {
  return (
    <React.Fragment>
      <BackgroundPage background={ScreeningsBG} />
      <div className="Screenings">
        <Table />
        <UploadScansPopup positionBtn="right" />
      </div>
    </React.Fragment>
  );
};

export default Screenings;
