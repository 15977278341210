import {
  RESET_MAIL_SUCCESS,
  RESET_MAIL_FAILURE,
  RESET_MAIL_FINALLY,
  RESET_MAIL_START,
} from '../actionsTypes';

const initialState = {
  loader: false,
  sendMail: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_MAIL_START:
      return {
        ...state,
        loader: true,
      };

    case RESET_MAIL_SUCCESS:
      return {
        ...state,
        sendMail: action.payload,
      };

    case RESET_MAIL_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };

    case RESET_MAIL_FINALLY:
      return {
        ...state,
        loader: false,
      };

    default:
      return state;
  }
};
