import { SELECT_BODY } from '../actionsTypes';

export const selectBodyNav = (name, select) => {
  return dispatch => {
    dispatch({
      type: SELECT_BODY,
      payload: {
        name: name,
        select: select,
      },
    });
  };
};
