import React from 'react';

import PopupOver from '../PopupOver/';
import Button from '../Button/';

import './index.scss';

const ConfirmationPopup = ({
  togglePopup,
  titlePopup,
  textPopup,
  onClickAction,
  labelButton,
  onClose,
}) => {
  return (
    togglePopup && (
      <PopupOver title={titlePopup} onClose={onClose}>
        <div className="ConfirmationPopup">
          <div className="ConfirmationPopup__title">{textPopup}</div>
          <Button label={labelButton} onClick={onClickAction} />
        </div>
      </PopupOver>
    )
  );
};

export default ConfirmationPopup;
