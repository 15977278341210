import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

import Input from '../../../../../components/Input/';
import Spinner from '../../../../../components/spinner/';
import ButtonSubmit from '../../../../../components/ButtonSubmit/';
import GoogleLogIn from '../../../Registration/components/GoogleLogIn';
// import FacebookLogIn from '../../../Registration/components/FacebookLogIn';

import { signInActions } from '../../../../../redux/actions/signInAction';
import { sessionActions } from '../../../../../redux/actions/sessionActions';

import { ROUT } from '../../../../../constants/';

import { requiredEmail, required, minValue8 } from '../../../helpers/';

import './index.scss';

class SignInForm extends Component {
  state = {
    errorSocial: false,
    errorSocialContent: '',
    errorStatus: '',
  };
  renderInputEmail = ({ input, meta, errorMessageBackEnd }) => (
    <Input
      {...input}
      type="E-mail"
      placeholder="E-mail"
      errorMessageBackEnd={errorMessageBackEnd}
      errorMessage={meta.touched && meta.error}
    />
  );
  renderInputPassword = ({ input, meta, errorMessageBackEnd }) => (
    <Input
      {...input}
      type="password"
      placeholder="Password"
      errorMessageBackEnd={errorMessageBackEnd}
      errorMessage={meta.touched && meta.error}
    />
  );

  onSubmit = async values => {
    await this.props.signInActions(values);
    this.props.sessionActions();
  };

  onChangeInput = () => {
    this.setState({
      errorSocialContent: '',
      errorStatus: '',
      errorSocial: false,
    });
  };

  onErrorSocial = (values, status, content) => {
    this.setState({
      errorSocial: values,
      errorSocialContent: content,
      errorStatus: status,
    });
  };

  render() {
    const { handleSubmit, valid, loader, errors } = this.props;
    const { errorSocial, errorStatus, errorSocialContent } = this.state;
    const renderError = () => {
      if (errorSocial && errorSocialContent && errorStatus === 401) {
        return <span className="signInForm__error">{errorSocialContent}</span>;
      } else if (errorSocial && errorSocialContent) {
        return errorSocialContent.map((item, index) => (
          <span key={index} className="signInForm__error">
            {item}
          </span>
        ));
      } else return null;
    };
    return (
      <div className="signInForm">
        <h2 className="signInForm__title">Sign in to Adam</h2>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <Field
            name="email"
            component={this.renderInputEmail}
            validate={[requiredEmail, required]}
            onChange={this.onChangeInput}
          />
          <Field
            name="password"
            component={this.renderInputPassword}
            validate={[minValue8, required]}
            onChange={this.onChangeInput}
          />
          <div className="signInForm__list">
            <GoogleLogIn onErrorSocial={this.onErrorSocial} />
            {/*<FacebookLogIn />*/}
          </div>
          <Link
            to={ROUT.forgotYourPassword}
            className="signInForm__reset-password"
          >
            Forgot your password?
          </Link>
          {renderError()}
          {errors && (
            <span className="signInForm__error">
              {errors.data.non_field_errors[0]}
            </span>
          )}
          <div className="signInForm__button-submit">
            <ButtonSubmit label="Sign In" disabled={!valid} />
          </div>
        </form>
        <Spinner isFetching={loader} />
      </div>
    );
  }
}

SignInForm = reduxForm({
  form: 'sighIn-form',
})(SignInForm);

const mapStateToProps = state => {
  return {
    loader: state.signIn.loader,
    errors: state.signIn.errors,
  };
};

const mapDispatchToProps = {
  signInActions,
  sessionActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignInForm);
