import React from 'react';

import './index.scss';

const BackgroundPage = ({ background }) => (
  <div
    style={{ backgroundImage: `url(${background})` }}
    className="BackgroundPage"
  />
);

export default BackgroundPage;
