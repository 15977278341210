import React from 'react';
import {AboutBody, AboutHeader} from "../../components/AboutProject";


const AboutProject = () => {
  return(
      <div style={{padding: 10}}>
        <AboutHeader />
        <AboutBody />
      </div>
  )
}

export default AboutProject;

