export const ROUT = {
  signIn: '/sign-in',
  registration: '/registration',
  profile: '/profile',
  bodyModel: '/body-model',
  screenings: '/screenings',
  forgotYourPassword: '/forgot-your-password',
  resetPassword: '/reset-password',
  privacyPolicy: '/privacyPolicy',
  terms: '/terms'
};
