import React from 'react';

export const ArrowTop = () => (
  <svg
    width="25"
    height="15"
    viewBox="0 0 25 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M24 14L12.5 2L1 14" stroke="#A0A0A0" strokeWidth="2" />
  </svg>
);

export const ArrowBottom = () => (
  <svg
    width="25"
    height="15"
    viewBox="0 0 25 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L12.5 13L24 1" stroke="#A0A0A0" strokeWidth="2" />
  </svg>
);
