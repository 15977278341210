import jwt_decode from 'jwt-decode';

import { STORAGE_TOKEN, STORAGE_TOKEN_CLEAR } from '../actionsTypes';

import { api } from '../../helpers/api';

import setAuthToken from '../../helpers/JWTSetToken';

const getStorageToken = () => localStorage.getItem('JVTToken');

export const sessionActions = () => async dispatch => {
  setAuthToken(getStorageToken());
  const token = getStorageToken();

  const newToken = token => {
    return api
      .fetchTokenRefresh(token)
      .then(response => response.data.token)
      .catch(error => console.log(error));
  };

  let decoded = token && jwt_decode(token);
  let currentTime = Math.floor(Date.now() / 1000);

  if (token) {
    if (currentTime >= decoded.exp) {
      console.log('expired');
      const updatedToken = await newToken(token);
      localStorage.setItem('JVTToken', updatedToken);
    } else {
      dispatch({
        type: STORAGE_TOKEN,
        payload: {
          storageToken: true,
        },
      });
    }
  } else {
    dispatch({
      type: STORAGE_TOKEN_CLEAR,
      payload: {
        storageToken: false,
      },
    });
  }
};
