import React from 'react';
import { Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Moment from 'moment';

import { ROUT } from '../../../../constants/';

const dataTip = ({
  institution_name,
  study_date,
  station_name,
  study_description,
  modality,
  display_body_part,
}) => {
  const date = Moment(study_date)
    .local()
    .format('DD.MM.YYYY');

  return (
    <div className="Avatar-tooltip">
      <div className="Avatar-tooltip__title">
        <span className="Avatar-tooltip__text Avatar-tooltip__text--bold">
          {modality}{' '}
        </span>
        <span className="Avatar-tooltip__text">{date} </span>
        <span className="Avatar-tooltip__text">{display_body_part}</span>
      </div>
      <p className="Avatar-tooltip__name">
        <span>Modality: </span>
        {modality}
      </p>
      <p className="Avatar-tooltip__name">
        <span>Institution Name: </span>
        {institution_name}
      </p>
      <p className="Avatar-tooltip__name">
        <span>Station Name: </span>
        {station_name}
      </p>
      <p className="Avatar-tooltip__name">
        <span>Study Description: </span>
        {study_description}
      </p>
      <Link to={ROUT.screenings} className="Avatar-tooltip__link">
        READ MORE...
      </Link>
    </div>
  );
};

const Tooltip = ({ ItemLayers }) => {
  return (
    <Popup
      on="click"
      content={dataTip(ItemLayers.info)}
      trigger={
        <div className={`Avatar__zone Avatar__zone--${ItemLayers.name}`} />
      }
      position="top center"
      hideOnScroll={true}
    />
  );
};

export default Tooltip;
