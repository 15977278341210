import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, reset } from 'redux-form';

import PopupOver from '../../../../components/PopupOver/';
import Button from '../../../../components/Button/';
import Spinner from '../../../../components/spinner/';
import ButtonSubmit from '../../../../components/ButtonSubmit/';
import Checkbox from '../../../../components/Checkbox/';
import ConfirmationPopup from '../../../../components/ConfirmationPopup/';

import { required, minValue8, tooOld, number } from '../../../Auth/helpers/';
import { api } from '../../../../helpers/api';

import { profileAction } from '../../../../redux/actions/profileAction';

const LazyInput = ({
  name,
  errors,
  type = 'text',
  placeholder,
  maxLength,
  short,
  ...props
}) => {
  return (
    <div className="lazyInput">
      <input
        {...props}
        className={
          short
            ? 'inputForm__input lazyInput--input shortInput'
            : 'inputForm__input lazyInput--input'
        }
        name={name}
        type={type}
        placeholder={placeholder}
        maxLength={maxLength}
      />
      {errors ? (
        <p className="lazyInput__placeholder lazyInput__placeholder--error">
          {errors}
        </p>
      ) : (
        <p className="lazyInput__placeholder">{placeholder}</p>
      )}
    </div>
  );
};

class ProfileEditPopUp extends Component {
  state = {
    profileEditPopUp: false,
    deleteUserPopup: false,
    loaderDel: false,
  };

  componentDidMount() {
    return this.props.initialValues;
  }

  onClosePopup = () => {
    this.props.reset();
    this.setState({
      profileEditPopUp: false,
    });
  };

  onProfileEditPopUp = () => {
    this.setState({
      profileEditPopUp: !this.state.profileEditPopUp,
    });
  };

  onSubmit = async data => {
    try {
      await api.fetchProfileEdit(data);
    } catch (error) {
      console.log(error);
    } finally {
      this.props.profileAction();
      this.setState({
        profileEditPopUp: false,
      });
    }
  };

  onDeleteUser = async () => {
    try {
      this.setState({
        deleteUserPopup: !this.state.deleteUserPopup,
        loaderDel: !this.state.loaderDel,
      });

      await api.fetchDeleteUser();
    } finally {
      localStorage.removeItem('JVTToken');
      window.location.reload();
    }
  };

  onTogglePopup = () => {
    this.setState({
      deleteUserPopup: !this.state.deleteUserPopup,
    });
  };

  renderInput = ({
    input,
    short,
    placeholder,
    name,
    maxLength,
    type,
    errors,
    meta: { touched, error, warning },
  }) => {
    return (
      <div>
        <LazyInput
          {...input}
          type={type}
          maxLength={maxLength}
          name={name}
          placeholder={placeholder}
          short={short}
          errors={touched && ((error && error) || (warning && warning))}
        />
      </div>
    );
  };

  RadioGroup = ({ input, options }) => {
    return (
      <div className="profile__list">
        {options.map((item, index) => (
          <Checkbox
            {...input}
            checked={+input.value === item.value}
            label={item.title}
            id={index}
            type="radio"
            value={item.value}
            key={item.value}
          />
        ))}
      </div>
    );
  };

  render() {
    const { profileEditPopUp, deleteUserPopup, loaderDel } = this.state;
    const { handleSubmit, submitting, pristine, reset } = this.props;

    return (
      <div className="profile--popup">
        <Button
          onClick={this.onProfileEditPopUp}
          label="Edit profile"
          className="Button--normal"
        />
        {profileEditPopUp && (
          <PopupOver title="Edit profile" onClose={this.onClosePopup}>
            <div className="popup-content">
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <div className="popup-content--input">
                  <div className="popup-content--input_leftsection">
                    <Field
                      name="first_name"
                      component={this.renderInput}
                      placeholder="First Name"
                      maxLength="30"
                    />
                    <Field
                      name="last_name"
                      component={this.renderInput}
                      placeholder="Last Name"
                      maxLength="30"
                    />
                    <Field
                      short
                      name="age"
                      component={this.renderInput}
                      placeholder="Age"
                      validate={[required, number, minValue8, tooOld]}
                      warn={tooOld}
                    />
                    <div>
                      <Field
                        name="gender"
                        component={this.RadioGroup}
                        options={[
                          { title: 'MALE', value: 1 },
                          { title: 'FEMALE', value: 2 },
                          { title: 'OTHER', value: 3 },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="popup-content--input_rightsection">
                    <p className="lazyInput--placeholder">Additional info</p>
                    <Field
                      name="additional_info"
                      component="textarea"
                      placeholder="Here you can write your own characteristics, such as: blood type, allergic reactions, etc."
                      cols="30"
                      rows="10"
                      maxLength="2000"
                    />
                  </div>
                </div>

                <div className="popup-content--button">
                  <ul>
                    <li
                      className="popup-content--link"
                      onClick={this.onTogglePopup}
                    >
                      Delete profile
                    </li>
                    <li>
                      <Button
                        label="Discard changes"
                        disabled={pristine || submitting}
                        onClick={reset}
                        className="Button--link"
                      />
                    </li>
                  </ul>
                  <ButtonSubmit
                    label="Save"
                    disabled={pristine || submitting}
                  />
                </div>
              </form>
            </div>
            <ConfirmationPopup
              togglePopup={deleteUserPopup}
              onClickAction={this.onDeleteUser}
              labelButton="Delete profile"
              textPopup={
                <div className="profile__delete-text">
                  Please, pay your attention!
                  <br />
                  <br /> There is no way to recover your account and all related
                  data after deletion. <br />
                  <br />A you sure you want to delete your account forever?
                </div>
              }
              titlePopup="Delete profile"
              onClose={this.onTogglePopup}
            />
            <Spinner isFetching={loaderDel} />
          </PopupOver>
        )}
      </div>
    );
  }
}

ProfileEditPopUp = reduxForm({
  form: 'editFormProfile',
  enableReinitialize: true,
})(ProfileEditPopUp);

const mapStateToProps = state => {
  return {
    initialValues: state.profile.user ? state.profile.user : null,
  };
};

const mapDispatchToProps = dispatch => ({
  reset: () => dispatch(reset('initialValues')),
  profileAction: () => dispatch(profileAction()),
});

ProfileEditPopUp = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileEditPopUp);

export default ProfileEditPopUp;
