import React from 'react';

import './index.scss';

const ButtonSubmit = ({ label, className = '', disabled = false, onClick }) => (
  <button
    type="submit"
    onClick={onClick}
    className={`ButtonSubmit ${className}`}
    disabled={disabled}
  >
    {label}
  </button>
);

export default ButtonSubmit;
