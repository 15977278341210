import React from 'react';
import { useDropzone } from 'react-dropzone';

import './DragAndDrop.scss';

import dragImg from '../../assets/img/drag_and_drop.png';

const DragAndDrop = ({ onFileDrag }) => {
  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    multiple: true,
    accept: '/*',
    onDrop: onFileDrag,
    noClick: true,
    maxSize: 1000000000,
  });

  return (
    <div
      {...getRootProps({
        className: `DragAndDrop ${isDragActive ? 'DragAndDrop--active' : ''}`,
      })}
    >
      <input {...getInputProps()} />
      <img className="DragAndDrop__item" src={dragImg} alt="drag and drop" />
      <p className="DragAndDrop__item DragAndDrop__item--text">
        Drag and drop your files or upload them
      </p>
    </div>
  );
};

export default DragAndDrop;
