import React from 'react';

import HederAuth from '../../../components/header/HederAuth';
import BackgroundPage from '../../../components/BackgroundPage/';
import SignInForm from '../SignIn/components/SignInForm/';

import background from '../../../assets/img/1024_768_BG.png';

import { ROUT } from '../../../constants/';

import './index.scss';

const LogIn = () => {
  return (
    <>
      <HederAuth text="Sign up" link={ROUT.registration} registerLink={true} />
      <BackgroundPage background={background} />
      <div className="authForm">
        <SignInForm />
      </div>
    </>
  );
};

export default LogIn;
