import React from 'react';
import {PrivacyBody, PrivacyHeader} from "../../components/Privacy";


const PrivacyPolicy = () => {
  return(
      <div style={{padding: 10}}>
        <PrivacyHeader />
        <PrivacyBody />
      </div>
  )
}

export default PrivacyPolicy;

