import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import ButtonSubmit from '../../../../../components/ButtonSubmit/';
import Checkbox from '../../../../../components/Checkbox/';

import { setRegisterUser } from '../../../../../redux/actions/registrationAction';
import { sessionActions } from '../../../../../redux/actions/sessionActions';

import { required } from '../../../helpers/';

import './index.scss';
import { AboutBody } from '../../../../../components/AboutProject';

class PopupAccept extends Component {
  onSubmit = async () => {
    const registrationForm = this.props.registrationForm.values;
    this.props.popupConfirm();
    await this.props.setRegisterUser({
      ...registrationForm,
      password2: registrationForm.password1,
    });
    this.props.sessionActions();
  };

  checkbox = ({ input }) => (
    <Checkbox
      {...input}
      label="Read and understood"
      type="checkbox"
      name="checkbox"
      value="1"
    />
  );

  render() {
    const { handleSubmit, valid } = this.props;

    return (
      <React.Fragment>
        <div className="popup-accept">
          <AboutBody />
        </div>
        <form
          onSubmit={handleSubmit(this.onSubmit)}
          className="popup-accept__form"
        >
          <Field
            name="checkbox"
            component={this.checkbox}
            validate={required}
          />
          <ButtonSubmit label="Sign Up" disabled={!valid} />
        </form>
      </React.Fragment>
    );
  }
}

PopupAccept = reduxForm({
  form: 'popupAccept',
})(PopupAccept);

const mapStateToProps = state => {
  return {
    registrationForm: state.form.registrationForm,
  };
};

const mapDispatchToProps = {
  setRegisterUser,
  sessionActions,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopupAccept);
