import {
  FETCH_REGISTER_START,
  FETCH_REGISTER_SUCCESS,
  FETCH_REGISTER_FAILURE,
  FETCH_REGISTER_FINALLY,
  FETCH_ERROR_ACTION_POPUP,
} from '../actionsTypes';

import { api } from '../../helpers/api';

export const setRegisterUser = param => async dispatch => {
  dispatch({ type: FETCH_REGISTER_START });

  try {
    const user = await api.fetchRegistration(param).then(response => response);

    localStorage.setItem('JVTToken', user.data.token);

    dispatch({
      type: FETCH_REGISTER_SUCCESS,
      payload: user,
    });
  } catch (error) {
    const message = error.response;
    dispatch({
      type: FETCH_REGISTER_FAILURE,
      payload: message,
      error: true,
    });

    if (error.response.status > 400) {
      dispatch({
        type: FETCH_ERROR_ACTION_POPUP,
      });
    }
  } finally {
    dispatch({
      type: FETCH_REGISTER_FINALLY,
    });
  }
};
