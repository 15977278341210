import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { chunk, uniqBy, flattenDeep } from 'lodash';
import DragAndDrop from './DragAndDrop';
import ReactTable from 'react-table';
import Moment from 'moment';
import { ROUT } from '../../constants/';

import Button from '../Button/';
import PopupOver from '../PopupOver/';
import Select from '../Select/';
import Spinner from '../spinner/';
import { api } from '../../helpers/api';
import ProgressBackup from '../progressBackup/';
import { screeningsAction } from '../../redux/actions/screeningsAction';
import { bodyModelAction } from '../../redux/actions/bodyModelAction';

import './index.scss';
import 'react-table/react-table.css';

const validationFiles = files => {
  const DICOMFILE = /\.(dcm)$/i;
  const R_ACCEPT_DICOM = /[.]/;
  const filteredAloneFileInDirResult = [];

  function createdNamesPathArr() {
    const namesPathArr = [];
    for (let i = 0; i < files.length; i++) {
      let path = files[i].path ? files[i].path : files[i].webkitRelativePath;
      let lengthSlice = path.lastIndexOf('/');
      let pathWithoutName = path.substring(0, lengthSlice);
      namesPathArr.push(pathWithoutName);
    }
    return namesPathArr;
  }

  async function filteredAloneFileInDir() {
    const result = await createdNamesPathArr();

    for (let i = 0; i < result.length; i++) {
      let coincidence = 0;
      for (let k = 0; k < result.length; k++) {
        if (result[i] === result[k]) {
          coincidence++;
        }
      }

      if (
        coincidence > 1 ||
        (!coincidence === 1 &&
          !files[i].name === 'DICOMDIR' &&
          !files[i].name === 'IM000000')
      ) {
        filteredAloneFileInDirResult.push(files[i]);
      }
    }

    return filteredAloneFileInDirResult.filter(
      file => !R_ACCEPT_DICOM.exec(file.name) || DICOMFILE.test(file.name)
    );
  }
  const validationFilteredFiles = filteredAloneFileInDir();
  return validationFilteredFiles;
};

const sizeFiles = files => {
  const sizeArray = files.map(item => item.size);
  return sizeArray.reduce((sum, current) => sum + current, 0);
};

export class UploadScansPopup extends Component {
  state = {
    popup: false,
    uploadScans: true,
    filesYouUploaded: false,
    filesYouUploadedProgress: false,
    filesYouUploadedError: false,
    filesYouUploadedLast: false,
    titlePopup: 'Upload scans',
    files: [],
    size: [],
    noDrag: true,
    progress: 0,
    dataStudies: [],
    initialBodyPart: [],
    maxSize: false,
  };

  fileInputRef = React.createRef();

  setStateAfterDrag = (files, size) => {
    const maxSize = (size / 1000000).toFixed(2);
    if (maxSize > 1000) {
      this.setState({
        maxSize: true,
      });
    }

    if (files.length) {
      this.setState({
        uploadScans: false,
        filesYouUploaded: true,
        titlePopup: 'the files you uploaded',
        files: files,
        size: maxSize,
      });
    }
  };

  toggleStatePopup = () => {
    this.setState({
      popup: !this.state.popup,
      uploadScans: true,
      filesYouUploaded: false,
      filesYouUploadedProgress: false,
      filesYouUploadedError: false,
      filesYouUploadedLast: false,
      files: [],
      size: [],
      progress: 0,
      maxSize: false,
    });
  };

  onOpenPopup = () => this.toggleStatePopup();

  onSelectChange = (values, valClickSelect, idStudies) => {
    const { dataStudies, initialBodyPart } = this.state;

    dataStudies.forEach((item, index) => {
      if (item.id === idStudies) {
        dataStudies[index].display_body_part = values.length
          ? values
          : initialBodyPart[index];
        dataStudies[index].idSelectStudies = values;
      }
    });
  };

  onConfirmPopup = async () => {
    const { screeningsAction, bodyModelAction } = this.props;
    const { dataStudies } = this.state;

    const allUpdate = dataStudies.map((item, index) =>
      item.idSelectStudies.length
        ? api
            .fetchStudyUpdate({
              study_id: item.id,
              body_part: item.display_body_part,
            })
            .then(response => {
              dataStudies[index].idSelectStudies = response.data.Study.map(
                item => item.id
              );
            })
        : null
    );

    await Promise.all(allUpdate);

    const idIes = dataStudies.map(item => {
      const idSelectStudies = item.idSelectStudies ? item.idSelectStudies : [];
      return [item.id, ...idSelectStudies];
    });

    const flattenData = flattenDeep(idIes);

    api.fetchArchiveStudy(flattenData);
    screeningsAction();
    bodyModelAction();
    this.toggleStatePopup();
  };

  onClosePopup = async () => {
    const { dataStudies } = this.state;

    const deleteStudyAllUpload = dataStudies.map(item =>
      api.fetchDeleteStudy(item.id)
    );

    api.fetchCancel();
    this.toggleStatePopup();
    await Promise.all(deleteStudyAllUpload);
  };

  onUpload = async () => {
    const { files } = this.state;

    const chunkArray = chunk(files, 10);

    const allProgress = promiseAll => {
      let time = 0;
      const len = promiseAll.length;
      promiseAll.forEach(item => {
        item.then(() => {
          time++;
          const progress = Math.round((time / len) * 100);
          this.setState({ progress });
        });
      });

      return Promise.all(promiseAll);
    };

    const promiseAll = chunkArray.map(item => {
      const data = new FormData();
      const pathFiles = item.map(element =>
        element.path ? element.path : element.webkitRelativePath
      );
      item.forEach(file => data.append('file', file));
      data.append('path', pathFiles);
      this.setState({
        filesYouUploaded: false,
        filesYouUploadedProgress: true,
      });
      return api.fetchUploadFiles(data);
    });

    const allData = [];

    allProgress(promiseAll)
      .then(response => {
        response.forEach(item => {
          const studies = item.data.Studies;
          studies.forEach(item => {
            allData.push(item);
          });
        });

        const uniqData = uniqBy(allData, 'id');
        this.setState({
          dataStudies: uniqData,
          initialBodyPart: uniqData.map(item => item.display_body_part),
        });

        setTimeout(() => {
          this.setState({
            filesYouUploadedError: false,
            filesYouUploadedProgress: false,
            filesYouUploadedLast: true,
          });
        }, 1000);
      })
      .catch(err => {
        this.setState({
          filesYouUploadedError: true,
          filesYouUploadedProgress: false,
        });
        console.error('err', err);
      });
  };

  onReload = () => {
    this.setState({
      uploadScans: true,
      filesYouUploaded: false,
      filesYouUploadedProgress: false,
      filesYouUploadedError: false,
      filesYouUploadedLast: false,
      files: [],
      size: [],
      progress: 0,
    });
  };

  onFileDrag = async acceptedFiles => {
    const files = await validationFiles(acceptedFiles);
    const size = await sizeFiles(files);

    this.setStateAfterDrag(files, size);
  };

  onDirectoryAdded = async ({ target }) => {
    const list = target.files;
    const filesArray = [];

    for (var index = 0; index < list.length; index++) {
      filesArray.push(list.item(index));
    }

    const files = await validationFiles(filesArray);
    const size = await sizeFiles(files);

    this.setStateAfterDrag(files, size);
  };

  AddScans = () => this.fileInputRef.current.click();

  uploadScansRender = () => {
    return (
      <div className="UploadScansPopup">
        <DragAndDrop onFileDrag={this.onFileDrag} />
        <div className="UploadScansPopup__text">
          <p>
            If we recognize that the study does not relate to the skeletal system, we mark it as the "none skeletal one".
            You can find them in the screenings list, but we will not show it on the skeletal model.
            Sometimes a study covers more than one body part. In such a case you will see them in
            separate lines.
          </p>
        </div>
        <input
          ref={this.fileInputRef}
          className="UploadScansPopup__input"
          type="file"
          webkitdirectory="true"
          mozdirectory="true"
          multiple
          onChange={this.onDirectoryAdded}
        />
        <Button label="Add scans" onClick={this.AddScans} />
      </div>
    );
  };

  filesYouUploadedRender = () => {
    const { files, size, maxSize } = this.state;
    return (
      <div className="UploadScansPopup">
        <p className="UploadScansPopup__file">
          {files.length} items look like DICOM format files
        </p>
        <p
          className={`UploadScansPopup__size ${
            maxSize ? 'UploadScansPopup__size--error' : null
          } `}
        >
          {size} MB
        </p>
        {maxSize ? (
          <p className="UploadScansPopup__text UploadScansPopup__text--error">
            The uploaded files are too large. The max files size should be up to
            1000 MB.
          </p>
        ) : null}
        <div className="UploadScansPopup__text">
          <p>
            When we asses, that the study does not relate to the skeletal
            system, we mark it as the "none skeletal one". You will find them in
            the screenings list, but we will not show it on the skeletal model.
          </p>
          <p>
            Sometimes a study covers more than one body part. You will see them
            in separate lines.
          </p>
        </div>
        <Button
          label="Upload scans"
          onClick={this.onUpload}
          disabled={maxSize}
        />
      </div>
    );
  };

  filesYouUploadedProgressRender = () => {
    const { files, size, progress } = this.state;
    return (
      <div className="UploadScansPopup UploadScansPopup--progress">
        <p className="UploadScansPopup__file">{files.length} files</p>
        <p className="UploadScansPopup__size">{size} MB</p>
        <ProgressBackup
          value={progress ? progress : '0'}
          name="Upload status"
          className="progress__title--center"
        />
      </div>
    );
  };

  filesYouUploadedErrorRender = () => {
    return (
      <div className="UploadScansPopup">
        <div className="UploadScansPopup__error">
          <p>Error</p>
          <p>loading failed</p>
        </div>
        <Button label="Reload" onClick={this.onReload} />
      </div>
    );
  };

  filesYouUploadedProgressLast = () => {
    const { dataStudies } = this.state;

    const columns = [
      {
        Header: 'Type',
        accessor: 'modality',
        minWidth: 50,
      },
      {
        id: 'Date',
        Header: 'Date',
        accessor: d => {
          return Moment(d.study_date)
            .local()
            .format('DD-MM-YYYY');
        },
        minWidth: 130,
      },
      {
        Header: 'Body part',
        accessor: 'body_part_examined',
        Cell: row => {
          const { original } = row;
          return (
            <Select
              label=""
              idStudies={original.id}
              value={this.onSelectChange}
              placeholder={original.display_body_part.map(item => item)}
              multiple
              options={[
                { value: 'Head', dataId: 0, disabled: false },
                { value: 'Neck', dataId: 0, disabled: false },
                { value: 'Chest', dataId: 0, disabled: false },
                { value: 'Spine', dataId: 0, disabled: false },
                { value: 'Pelvis', dataId: 0, disabled: false },
                { value: 'Left Femur', dataId: 0, disabled: false },
                { value: 'Right Femur', dataId: 0, disabled: false },
                { value: 'Left Lower Leg', dataId: 0, disabled: false },
                { value: 'Right Lower Leg', dataId: 0, disabled: false },
                { value: 'Left Foot', dataId: 0, disabled: false },
                { value: 'Right Foot', dataId: 0, disabled: false },
                { value: 'Left Shoulder', dataId: 0, disabled: false },
                { value: 'Right Shoulder', dataId: 0, disabled: false },
                { value: 'Left Forearm', dataId: 0, disabled: false },
                { value: 'Right Forearm', dataId: 0, disabled: false },
                { value: 'Left Wrist', dataId: 0, disabled: false },
                { value: 'Right Wrist', dataId: 0, disabled: false },
                { value: 'None skeletal', dataId: 1, disabled: false },
                { value: 'Not recognized', dataId: 1, disabled: false },
              ]}
            />
          );
        },
        minWidth: 220,
      },
      {
        id: 'Description',
        Header: 'Description',
        accessor: d => {
          return (
            <div className="ReactTable__description">
              <p>
                <span className="ReactTable__details">Patient's Name:</span>{' '}
                {d.patient_name}
              </p>
              <p>
                <span className="ReactTable__details">Body Part Examined:</span>{' '}
                {d.body_part_examined}
              </p>
              <p>
                <span className="ReactTable__details">Study description:</span>{' '}
                {d.study_description}
              </p>
              <p>
                <span className="ReactTable__details">Institution name:</span>{' '}
                {d.institution_name}
              </p>
            </div>
          );
        },
        minWidth: 300,
      },
    ];

    return (
      <div className="UploadScansPopup">
        {dataStudies && dataStudies.length ? (
          <div>
            <div className="UploadScansPopup__title">
              Your files are recognized.
            </div>
            <div className="UploadScansPopup__description">
              <h2 className="UploadScansPopup__heading">
                Please check if we are right in body part recognition:
              </h2>
              In some cases, we are not able to find the right body part of the
              uploaded study. You will see "not recognized" option or "None
              skeletal" option for the non skeletal screenings. Please choose
              the part, if know the right one.
            </div>
            <ReactTable
              data={dataStudies && dataStudies}
              columns={columns}
              showPagination={false}
              pageSize={dataStudies && dataStudies.length}
            />
            <Link
              to={ROUT.screenings}
              className="UploadScansPopup__btn"
              onClick={this.onConfirmPopup}
            >
              confirm
            </Link>
          </div>
        ) : (
          <div>
            <div className="UploadScansPopup__title">
              We have not found DICOM files.
            </div>
            <Button onClick={this.onConfirmPopup} label="Return" />
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      popup,
      uploadScans,
      filesYouUploaded,
      titlePopup,
      filesYouUploadedProgress,
      filesYouUploadedError,
      filesYouUploadedLast,
    } = this.state;
    const { positionBtn, screeningsLoader } = this.props;

    return (
      <div>
        <Button
          className={`BodyModel__btn ${
            positionBtn ? `BodyModel__btn--${positionBtn}` : ''
          }`}
          label="Upload scans"
          onClick={this.onOpenPopup}
        />
        {popup ? (
          <PopupOver title={titlePopup} onClose={this.onClosePopup}>
            {uploadScans && this.uploadScansRender()}
            {filesYouUploaded ? this.filesYouUploadedRender() : null}
            {filesYouUploadedProgress
              ? this.filesYouUploadedProgressRender()
              : null}
            {filesYouUploadedLast ? this.filesYouUploadedProgressLast() : null}
            {filesYouUploadedError ? this.filesYouUploadedErrorRender() : null}
          </PopupOver>
        ) : null}
        <Spinner isFetching={screeningsLoader} />
      </div>
    );
  }
}

const mapDispatchToProps = {
  screeningsAction,
  bodyModelAction,
};

const mapStateToProps = state => {
  return {
    screeningsLoader: state.screenings.loader,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadScansPopup);
