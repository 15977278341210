import {
  BODY_MODEL_START,
  BODY_MODEL_SUCCESS,
  BODY_MODEL_FAILURE,
  BODY_MODEL_FINALLY,
  FETCH_ERROR_ACTION_POPUP,
} from '../actionsTypes';

import { api } from '../../helpers/api';

export const bodyModelAction = () => async dispatch => {
  dispatch({ type: BODY_MODEL_START });

  try {
    const bodyModel = await api.fetchBodyModel().then(response => response);

    dispatch({
      type: BODY_MODEL_SUCCESS,
      payload: bodyModel.data.Study,
    });
  } catch (error) {
    const message = error.response;
    dispatch({
      type: BODY_MODEL_FAILURE,
      payload: message,
    });

    if (error.response.status > 400) {
      dispatch({
        type: FETCH_ERROR_ACTION_POPUP,
      });
    }
  } finally {
    dispatch({
      type: BODY_MODEL_FINALLY,
    });
  }
};
