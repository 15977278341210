import React from 'react';
import Scrollbar from 'react-scrollbars-custom';

import './index.scss';

const PopupOver = ({ children, title, onClose, close = true }) => {
  return (
    <div className="popup-over">
      <div className="popup-over__content">
        {close ? (
          <div className="popup-over__close" onClick={onClose}></div>
        ) : null}
        <h2 className="popup-over__heading">{title}</h2>
        <Scrollbar
          style={{ height: 550 }}
          maximalThumbYSize={77}
          noScrollX={true}
        >
          {children}
        </Scrollbar>
      </div>
    </div>
  );
};

export default PopupOver;
