import React from 'react';
import './index.scss';

import logo from '../../assets/img/logo.svg';

export default function Spinner({ isFetching }) {
  return isFetching ? (
    <div className="spinner">
      <img src={logo} alt="Logo" />
    </div>
  ) : null;
}
