import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './logo';

import { ROUT } from '../../constants/';

import './index.scss';

const HederAuth = ({ text, link, registerLink = false }) => {
  return (
    <header className="header">
      <div className="header__nav header__auth"/>
      <div className="header__logoWrapper header__logo__auth">
        <Logo className="header__logo" />
      </div>

      <div className="header__links">
        {registerLink ? (
          <Link to={ROUT.registration} className="header__text">
            Don't have an account?
          </Link>
        ) : null}
        {text ? (
          <Link to={link} className="header__btn">
            {text}
          </Link>
        ) : null}
      </div>
    </header>
  );
};

export default HederAuth;
