import React from 'react';

import HederAuth from '../../../components/header/HederAuth';
import BackgroundPage from '../../../components/BackgroundPage/';
import RegisterForm from './components/RegisterForm/';

import background from '../../../assets/img/1024_768_BG.png';

import { ROUT } from '../../../constants/';

import './index.scss';

const Registration = () => (
  <>
    <HederAuth text="Sign in" link={ROUT.signIn} />
    <BackgroundPage background={background} />
    <div className="registration">
      <RegisterForm />
    </div>
  </>
);

export default Registration;
