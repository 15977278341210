export const FETCH_REGISTER_START = 'FETCH_REGISTER_START';
export const FETCH_REGISTER_SUCCESS = 'FETCH_REGISTER_SUCCESS';
export const FETCH_REGISTER_FAILURE = 'FETCH_REGISTER_FAILURE';
export const FETCH_REGISTER_FINALLY = 'FETCH_REGISTER_FINALLY';

export const FETCH_REGISTER_EXIST_START = 'FETCH_REGISTER_EXIST_START';
export const FETCH_REGISTER_EXIST_SUCCESS = 'FETCH_REGISTER_EXIST_SUCCESS';
export const FETCH_REGISTER_EXIST_FAILURE = 'FETCH_REGISTER_EXIST_FAILURE';
export const FETCH_REGISTER_EXIST_FINALLY = 'FETCH_REGISTER_EXIST_FINALLY';

export const FETCH_SIGNIN_START = 'FETCH_SIGNIN_START';
export const FETCH_SIGNIN_SUCCESS = 'FETCH_SIGNIN_SUCCESS';
export const FETCH_SIGNIN_FAILURE = 'FETCH_SIGNIN_FAILURE';
export const FETCH_SIGNIN_FINALLY = 'FETCH_SIGNIN_FINALLY';

export const STORAGE_TOKEN = 'STORAGE_TOKEN';
export const STORAGE_TOKEN_CLEAR = 'STORAGE_TOKEN_CLEAR';

export const PROFILE_START = 'PROFILE_START';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_FAILURE = 'PROFILE_FAILURE';
export const PROFILE_FINALLY = 'PROFILE_FINALLY';

export const BODY_MODEL_START = 'BODY_MODEL_START';
export const BODY_MODEL_SUCCESS = 'BODY_MODEL_SUCCESS';
export const BODY_MODEL_FAILURE = 'BODY_MODEL_FAILURE';
export const BODY_MODEL_FINALLY = 'BODY_MODEL_FINALLY';

export const FETCH_ERROR_ACTION_POPUP = 'FETCH_ERROR_ACTION_POPUP';
export const FETCH_ERROR_CLEAR = 'FETCH_ERROR_CLEAR';

export const SELECT_BODY = 'SELECT_BODY';

export const SCREENINGS_START = 'SCREENINGS_START';
export const SCREENINGS_SUCCESS = 'SCREENINGS_SUCCESS';
export const SCREENINGS_FAILURE = 'SCREENINGS_FAILURE';
export const SCREENINGS_FINALLY = 'SCREENINGS_FINALLY';

export const SCAN_POPUP_UPLOAD = 'SCAN_POPUP_UPLOAD';

export const DELETE_STUDY = 'DELETE_STUDY';

export const SELECT_TABLE = 'SELECT_TABLE';

export const RESET_MAIL_START = 'RESET_MAIL_START';
export const RESET_MAIL_SUCCESS = 'RESET_SUCCESS';
export const RESET_MAIL_FAILURE = 'RESET_FAILURE';
export const RESET_MAIL_FINALLY = 'RESET_FINALLY';

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_FINALLY = 'RESET_PASSWORD_FINALLY';

export const RESET_PASSWORD_MIN_LENGTH_ERROR =
  'RESET_PASSWORD_MIN_LENGTH_ERROR';
