import { connect } from 'react-redux';

import {
  minLengthErrorAction,
  resetPasswordTokenErrorAction,
} from '../redux/actions/defaultActions';

import {
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_FINALLY,
} from '../redux/actionsTypes';

import { api } from '../helpers/api';

const mapStateToProps = state => ({
  sendPasswordToResetState: state.resetPassword.password,
  loader: state.resetPassword.loader,
});

const resetPassword = (data, history) => async dispatch => {
  if (data.new_password1.length < 8) {
    return dispatch(minLengthErrorAction());
  }

  try {
    const password = await api.fetchConfirmPassword(data);

    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: password.data,
    });

    return history.push('/sign-in');
  } catch (error) {
    const message = error.response;

    dispatch(resetPasswordTokenErrorAction());

    return dispatch({
      type: RESET_PASSWORD_FAILURE,
      payload: message,
      error: true,
    });
  } finally {
    return dispatch({
      type: RESET_PASSWORD_FINALLY,
    });
  }
};

const mapDispatchToProps = dispatch => ({
  resetPassword: (data, history) => dispatch(resetPassword(data, history)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
