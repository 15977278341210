import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { sessionActions } from '../redux/actions/sessionActions';

const PrivateRoute = ({ component: Component, user, redirectTo, ...rest }) => {
  rest.sessionActions();
  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          <React.Fragment>
            <Component {...props} />
          </React.Fragment>
        ) : (
          <Redirect to={redirectTo} />
        )
      }
    />
  );
};

const mapDispatchToProps = dispatch => ({
  sessionActions: () => dispatch(sessionActions()),
});

export default connect(
  null,
  mapDispatchToProps
)(PrivateRoute);
