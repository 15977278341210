import {
  SCREENINGS_START,
  SCREENINGS_SUCCESS,
  SCREENINGS_FAILURE,
  SCREENINGS_FINALLY,
  FETCH_ERROR_ACTION_POPUP,
} from '../actionsTypes';

import { api } from '../../helpers/api';

export const screeningsAction = () => async dispatch => {
  dispatch({ type: SCREENINGS_START });

  try {
    const screenings = await api.fetchScreenings().then(response => response);

    dispatch({
      type: SCREENINGS_SUCCESS,
      payload: screenings.data,
    });
  } catch (error) {
    const message = error.response;
    dispatch({
      type: SCREENINGS_FAILURE,
      payload: message,
      error: true,
    });

    if (error.response.status > 400) {
      dispatch({
        type: FETCH_ERROR_ACTION_POPUP,
      });
    }
  } finally {
    dispatch({
      type: SCREENINGS_FINALLY,
    });
  }
};
