import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route } from 'react-router-dom';
import { Router } from 'react-router-dom';
import { syncHistoryWithStore, routerReducer } from 'react-router-redux';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import App from './App';

import ErrorBoundary from './components/ErrorBoundary/';
import ErrorPopup from './components/ErrorPopup/';

import reducer from './redux/reducers/';

import historyBrowser from './history';

import './index.scss';

const middleware = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  combineReducers({
    ...reducer,
    routing: routerReducer,
  }),
  process.env.NODE_ENV !== 'production'
    ? composeEnhancers(applyMiddleware(...middleware))
    : applyMiddleware(...middleware)
);

const history = syncHistoryWithStore(historyBrowser, store);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <>
        <ErrorBoundary>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
        </ErrorBoundary>
        <ErrorPopup />
      </>
    </Router>
  </Provider>,
  document.getElementById('root')
);
