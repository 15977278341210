import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link, withRouter } from 'react-router-dom';

import ButtonSubmit from '../../components/ButtonSubmit/';
import Button from '../../components/Button/';
import Input from '../../components/Input/';
import Spinner from '../../components/spinner/';
import HederAuth from '../../components/header/HederAuth';

import { required, minValue8 } from '../Auth/helpers/';
import { parseUrl } from '../../helpers/parseUrl';

import './index.scss';
import withResetPassword from '../../decorator/withResetPassword';
import { compose } from 'recompose';

const renderInputPassword = ({ input, meta, errorMessageBackEnd }) => (
  <Input
    {...input}
    type="password"
    placeholder="password"
    errorMessageBackEnd={errorMessageBackEnd}
    errorMessage={meta.touched && meta.error}
  />
);

let ResetPassword = ({
  history,
  valid,
  loader,
  resetPassword,
  handleSubmit,
}) => {
  const onSubmit = ({ password }) => {
    const parseLink = parseUrl();

    resetPassword(
      {
        new_password1: password,
        new_password2: password,
        uid: parseLink.uid,
        token: parseLink.token,
      },
      history
    );
  };

  return (
    <React.Fragment>
      <HederAuth />
      <div className="reset-password">
        <h1 className="reset-password__title">RESET PASSWORD</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Field
            name="password"
            component={renderInputPassword}
            validate={[required, minValue8]}
          />
          <div className="reset-password__buttons">
            <Link to="/">
              <Button className="Button--initial-text" label="Home" />
            </Link>
            <ButtonSubmit label="send" disabled={!valid}  />
          </div>
        </form>
        <Spinner isFetching={loader} />
      </div>
    </React.Fragment>
  );
};

export default compose(
  reduxForm({
    form: 'ResetPassword',
  }),
  withRouter,
  withResetPassword
)(ResetPassword);
