import React, { Component } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import { ArrowTop, ArrowBottom } from '../Arrow/';
import { CloseIcon } from '../CloseIcon/';

import './index.scss';

export class Select extends Component {
  state = {
    values: [],
    focusedValue: -1,
    isFocused: false,
    isOpen: false,
    typed: '',
    valClickSelect: {},
    selectVolue: this.props.options,
  };

  onFocus = () => {
    this.setState({
      isFocused: true,
    });
  };

  onBlur = () => {
    const { options, multiple } = this.props;

    this.setState(prevState => {
      const { values } = prevState;

      if (multiple) {
        return {
          focusedValue: -1,
          isFocused: false,
          isOpen: false,
        };
      } else {
        const value = values[0];

        let focusedValue = -1;

        if (value) {
          focusedValue = options.findIndex(option => option.value === value);
        }

        return {
          focusedValue,
          isFocused: false,
          isOpen: false,
        };
      }
    });
  };

  onKeyDown = e => {
    const { options, multiple } = this.props;
    const { isOpen } = this.state;

    switch (e.key) {
      case ' ':
        e.preventDefault();
        if (isOpen) {
          if (multiple) {
            this.setState(prevState => {
              const { focusedValue } = prevState;

              if (focusedValue !== -1) {
                const [...values] = prevState.values;
                const value = options[focusedValue].value;
                const index = values.indexOf(value);

                if (index === -1) {
                  values.push(value);
                } else {
                  values.splice(index, 1);
                }

                return { values };
              }
            });
          }
        } else {
          this.setState({
            isOpen: true,
          });
        }
        break;
      case 'Escape':
      case 'Tab':
        if (isOpen) {
          e.preventDefault();
          this.setState({
            isOpen: false,
          });
        }
        break;
      case 'Enter':
        this.setState(prevState => ({
          isOpen: !prevState.isOpen,
        }));
        break;
      case 'ArrowDown':
        e.preventDefault();
        this.setState(prevState => {
          let { focusedValue } = prevState;

          if (focusedValue < options.length - 1) {
            focusedValue++;

            if (multiple) {
              return {
                focusedValue,
              };
            } else {
              return {
                values: [options[focusedValue].value],
                focusedValue,
              };
            }
          }
        });
        break;
      case 'ArrowUp':
        e.preventDefault();
        this.setState(prevState => {
          let { focusedValue } = prevState;

          if (focusedValue > 0) {
            focusedValue--;

            if (multiple) {
              return {
                focusedValue,
              };
            } else {
              return {
                values: [options[focusedValue].value],
                focusedValue,
              };
            }
          }
        });
        break;
      default:
        if (/^[a-z0-9]$/i.test(e.key)) {
          const char = e.key;

          clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.setState({
              typed: '',
            });
          }, 1000);

          this.setState(prevState => {
            const typed = prevState.typed + char;
            const re = new RegExp(`^${typed}`, 'i');
            const index = options.findIndex(option => re.test(option.value));

            if (index === -1) {
              return {
                typed,
              };
            }

            if (multiple) {
              return {
                focusedValue: index,
                typed,
              };
            } else {
              return {
                values: [options[index].value],
                focusedValue: index,
                typed,
              };
            }
          });
        }
        break;
    }
  };

  onClick = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  onDeleteOption = e => {
    const { value } = e.currentTarget.dataset;

    this.setState(prevState => {
      const [...values] = prevState.values;
      const index = values.indexOf(value);

      values.splice(index, 1);

      return { values };
    });
  };

  // onHoverOption = (e) => {
  //   const { options } = this.props

  //   const { value } = e.currentTarget.dataset
  //   const index = options.findIndex(option => option.value === value)

  //   this.setState({
  //     focusedValue: index
  //   })
  // }

  onClickOption = e => {
    const { multiple } = this.props;

    const { value, id } = e.currentTarget.dataset;
    this.setState(prevState => {
      if (!multiple) {
        return {
          values: [value],
          isOpen: false,
        };
      }

      const [...values] = prevState.values;
      const index = values.indexOf(value);

      if (index === -1) {
        values.push(value);
      } else {
        values.splice(index, 1);
      }

      return { values, valClickSelect: { value: value, dataId: id } };
    });
  };

  stopPropagation = e => {
    e.stopPropagation();
  };

  renderValues = () => {
    const { placeholder, multiple } = this.props;
    const { values } = this.state;

    if (values.length === 0) {
      return <div className="placeholder">{placeholder}</div>;
    }

    if (multiple) {
      return values.map(value => {
        return (
          <span
            key={value}
            onClick={this.stopPropagation}
            className="multiple value"
          >
            {value}
            <span
              data-value={value}
              onClick={this.onDeleteOption}
              className="delete"
            >
              <CloseIcon />
            </span>
          </span>
        );
      });
    }

    return <div className="value">{values[0]}</div>;
  };

  renderOptions = () => {
    const { isOpen, selectVolue, values, valClickSelect } = this.state;

    const dataIdSelect = +valClickSelect.dataId;
    if (dataIdSelect === 0 || dataIdSelect === 1) {
      selectVolue.forEach((item, index) => {
        if (item.dataId !== dataIdSelect) {
          selectVolue[index].disabled = true;
        }
        if (!values.length) {
          selectVolue[index].disabled = false;
        }
      });
    }

    if (!isOpen) {
      return null;
    }

    return (
      <div className="options">
        <Scrollbar
          style={{ height: 180 }}
          maximalThumbYSize={77}
          noScrollX={true}
        >
          {selectVolue.map(this.renderOption)}
        </Scrollbar>
      </div>
    );
  };

  renderOption = (option, index) => {
    const { multiple } = this.props;
    const { values, focusedValue } = this.state;

    const { value, dataId, disabled } = option;

    const selected = values.includes(value);

    let className = 'option';
    if (selected) className += ' selected';
    if (index === focusedValue) className += ' focused';

    return !disabled ? (
      <div
        key={value}
        data-value={value}
        data-id={dataId}
        className={className}
        // onMouseOver={this.onHoverOption}
        onClick={this.onClickOption}
      >
        {multiple ? (
          <span className="checkbox">{selected ? <CloseIcon /> : null}</span>
        ) : null}
        {value}
      </div>
    ) : null;
  };

  render() {
    const { label, value, idStudies } = this.props;
    const { isOpen, values, valClickSelect } = this.state;

    return (
      <div
        className="select"
        tabIndex="0"
        // onFocus={this.onFocus}
        onBlur={this.onBlur}
        // onKeyDown={this.onKeyDown}
        value={value(values, valClickSelect, idStudies)}
      >
        <label className="label">{label}</label>
        <div className="selection" onClick={this.onClick}>
          {this.renderValues()}
          <span className="arrow">
            {isOpen ? <ArrowTop /> : <ArrowBottom />}
          </span>
        </div>
        {this.renderOptions()}
      </div>
    );
  }
}

export default Select;

// <Select
//   label="React Select"
//   placeholder="Pick one"
//   options={[
//     { value: 'Rock' },
//     { value: 'Paper' },
//     { value: 'Scissors' }
//   ]}
// />
// <Select
//   label="React Multiple Select"
//   placeholder="Pick some"
//   options={[
//     { value: 'Rock' },
//     { value: 'Paper' },
//     { value: 'Scissors' }
//   ]}
//   multiple
// />
