export const minLengthErrorAction = () => ({
  type: '@@redux-form/UPDATE_SYNC_ERRORS',
  meta: {
    form: 'ResetPassword',
  },
  payload: {
    syncErrors: {
      password: 'Must be at least 8',
    },
  },
});

export const resetPasswordTokenErrorAction = () => ({
  type: '@@redux-form/UPDATE_SYNC_ERRORS',
  meta: {
    form: 'ResetPassword',
  },
  payload: {
    syncErrors: {
      password: 'This link is invalid',
    },
  },
});
