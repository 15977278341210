import React from 'react';
import { Link } from 'react-router-dom';

import HederAuth from '../../components/header/HederAuth';
import BackgroundPage from '../../components/BackgroundPage/';
import Button from '../../components/Button/';

import background from '../../assets/img/profile.jpg';

import './index.scss';

const NoMatch = () => {
  return (
    <React.Fragment>
      <HederAuth />
      <BackgroundPage background={background} />
      <div class="NoMatch">
        <h1 className="NoMatch__heading">404</h1>
        <p className="NoMatch__text NoMatch__text--uppercase">
          It looks like you’re lost...
        </p>
        <p className="NoMatch__text">
          The page you’re looking for doesn’t exist.
        </p>
        <Link className="NoMatch__link" to="/">
          <Button label="go back home" />
        </Link>
      </div>
    </React.Fragment>
  );
};

export default NoMatch;
