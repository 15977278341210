import {
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_FINALLY,
  RESET_PASSWORD_START,
} from '../actionsTypes';

const initialState = {
  loader: false,
  password: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_START:
      return {
        ...state,
        loader: true,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        password: action.payload,
      };

    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };

    case RESET_PASSWORD_FINALLY:
      return {
        ...state,
        loader: false,
      };

    default:
      return state;
  }
};
