import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import Input from '../../../../../components/Input/';
import Checkbox from '../../../../../components/Checkbox/';
import PopupOver from '../../../../../components/PopupOver/';
import Spinner from '../../../../../components/spinner/';
import ButtonSubmit from '../../../../../components/ButtonSubmit';
import PopupAccept from '../PopupAccept/';
import GoogleLogIn from '../GoogleLogIn/';
// import FacebookLogIn from '../FacebookLogIn/';

import {
  requiredEmail,
  required,
  minValue8,
  onlyNumbers,
} from '../../../helpers/';

import { existUser } from '../../../../../redux/actions/registrationExistsAction';

import './index.scss';
import { AboutHeader } from '../../../../../components/AboutProject';

class RegisterForm extends Component {
  state = {
    popup: false,
    errorMessageRequest: true,
    errorSocial: false,
    errorSocialContent: '',
    errorStatus: '',
  };

  onSubmit = async () => {
    const registrationForm = this.props.registrationForm.values;

    try {
      await this.props.existUser({
        ...registrationForm,
        password2: registrationForm.password1,
      });
    } catch (error) {
      console.log(error);
    } finally {
      if (this.props.existUserFetch.data === 'True') {
        this.setState({
          popup: !this.state.popup,
        });
      } else {
        this.setState({
          errorMessageRequest: true,
        });
      }
    }
  };

  onClosePopup = () => {
    this.setState({
      popup: !this.state.popup,
    });
  };

  renderInputEmail = ({ input, meta, errorMessageBackEnd }) => (
    <Input
      {...input}
      type="E-mail"
      placeholder="E-mail"
      errorMessageBackEnd={errorMessageBackEnd}
      errorMessage={meta.touched && meta.error}
    />
  );
  renderInputPassword = ({ input, meta, errorMessageBackEnd }) => (
    <Input
      {...input}
      type="password"
      placeholder="Password"
      errorMessageBackEnd={errorMessageBackEnd}
      errorMessage={meta.touched && meta.error}
    />
  );
  radioGroup = ({ input, meta, options }) => {
    const hasError = meta.touched && meta.error;
    return (
      <div className="registerForm__radio-list registerForm-wrap-error">
        {options.map((item, index) => (
          <Checkbox
            {...input}
            label={item.title}
            id={index}
            type="radio"
            name="radio"
            value={item.value}
            key={item.value}
          />
        ))}
        {hasError && (
          <span className="registerForm-wrap-error__error">{meta.error}</span>
        )}
      </div>
    );
  };

  onErrorSocial = (values, status, content) => {
    this.setState({
      errorSocial: values,
      errorStatus: status,
      errorSocialContent: content,
    });
  };

  onChangeInput = () =>
    this.props.existUserFetch
      ? this.setState({ errorMessageRequest: false })
      : null;

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      loader,
      existUserFetch,
    } = this.props;
    const {
      popup,
      errorMessageRequest,
      errorSocial,
      errorStatus,
      errorSocialContent,
    } = this.state;

    const renderError = () => {
      if (errorSocial && errorSocialContent && errorStatus === 401) {
        return <span className="signInForm__error">{errorSocialContent}</span>;
      } else if (errorSocial && errorSocialContent) {
        return errorSocialContent.map((item, index) => (
          <span key={index} className="signInForm__error">
            {item}
          </span>
        ));
      } else return null;
    };

    return (
      <div className="registerForm">
        <h2 className="registerForm__title">Account registration</h2>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <Field
            name="email"
            component={this.renderInputEmail}
            onChange={this.onChangeInput}
            errorMessageBackEnd={
              errorMessageRequest
                ? existUserFetch
                  ? existUserFetch.data.email
                  : null
                : null
            }
            validate={[requiredEmail, required]}
          />
          <Field
            name="password1"
            component={this.renderInputPassword}
            onChange={this.onChangeInput}
            errorMessageBackEnd={
              errorMessageRequest
                ? existUserFetch
                  ? existUserFetch.data.password1
                  : null
                : null
            }
            validate={[required, onlyNumbers, minValue8]}
          />
          <Field
            name="gender"
            component={this.radioGroup}
            options={[
              { title: 'MALE', value: '1' },
              { title: 'FEMALE', value: '2' },
              { title: 'OTHER', value: '3' },
            ]}
            validate={required}
          />
          <span className="registerForm__social">
            Log in via google or facebook
          </span>
          <div className="registerForm__list">
            <GoogleLogIn onErrorSocial={this.onErrorSocial} />
            {/*<FacebookLogIn />*/}
          </div>
          {renderError()}
          <div className="registerForm__button-submit">
            <ButtonSubmit label="Sign Up" disabled={pristine || submitting} />
          </div>
        </form>
        {popup && (
          <PopupOver title={<AboutHeader />} onClose={this.onClosePopup}>
            <PopupAccept popupConfirm={this.onClosePopup} />
          </PopupOver>
        )}
        <Spinner isFetching={loader} />
      </div>
    );
  }
}

RegisterForm = reduxForm({
  form: 'registrationForm',
})(RegisterForm);

const mapStateToProps = state => {
  return {
    loader: state.registrationExist.loader,
    user: state.registration.token,
    existUserFetch: state.registrationExist.user,
    registrationForm: state.form.registrationForm,
  };
};

const mapDispatchToProps = {
  existUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterForm);
