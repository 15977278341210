import React from 'react';
import { connect } from 'react-redux';
import './index.scss';

const ProgressBackup = ({
  value,
  className,
  bodyModelData,
  name = 'Backup status',
}) => {
  const listLength = bodyModelData.body.length;
  const valueBodyModelData = Math.round((listLength / 17) * 100);
  const renderValue = value ? value : valueBodyModelData;
  return (
    <div className={`progress ${className}`}>
      <div className="progress__value" style={{ width: `${renderValue}%` }}>
        {renderValue}%
      </div>
      <span className={`progress__title ${className}`}>{name}</span>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    bodyModelData: state.bodyModel,
  };
};

export default connect(
  mapStateToProps,
  null
)(ProgressBackup);
