import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GoogleLogin } from 'react-google-login';

import Spinner from '../../../../../components/spinner/';

import { sessionActions } from '../../../../../redux/actions/sessionActions';

import { api } from '../../../../../helpers/api';

import './index.scss';

class GoogleLogIn extends Component {
  state = {
    loader: false,
  };

  responseGoogle = async response => {
    try {
      this.setState({ loader: true });
      const user = await api
        .fetchSocial({ access_token: response.accessToken }, 'google')
        .then(response => response);
      this.setState({ loader: false });
      localStorage.setItem('JVTToken', user.data.token);
    } catch (error) {
      console.error('error :', error.response);
      this.setState({
        loader: false,
      });

      if (error.response.status === 401) {
        this.props.onErrorSocial(
          true,
          error.response.status,
          error.response.data.detail
        );
      } else {
        this.props.onErrorSocial(
          true,
          error.response.status,
          error.response.data.non_field_errors
        );
      }
    } finally {
      this.props.sessionActions();
    }
  };

  render() {
    const { loader } = this.state;
    return (
      <React.Fragment>
        <GoogleLogin
          onSuccess={this.responseGoogle}
          onFailure={this.responseGoogle}
          clientId="906940687568-fjch604dg8gpveh6paeia5mijno7aj4f.apps.googleusercontent.com"
          icon={false}
          style={{
            'background-color': 'none',
          }}
          className="ButtonSocial"
        >
          Google
        </GoogleLogin>
        <Spinner isFetching={loader} />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  sessionActions: () => dispatch(sessionActions()),
});

export default connect(
  null,
  mapDispatchToProps
)(GoogleLogIn);
