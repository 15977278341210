import React from 'react';

const CountStageItem = ({ count, stageHeader, stageBody }) => {
  return (
    <div className="countStage">
      <div className="countStage__stageNumber">
        <p className="countStage__count">{count < 10 ? `0` + count : count}</p>
        <p className="countStage__text">step</p>
      </div>
      <div className="countStage__stageBody">
        <p className="countStage__header">{stageHeader}</p>
        <p className="countStage__body">{stageBody}</p>
      </div>
    </div>
  );
};

const countData = [
  {
    id: 1,
    header: 'Upload CT or MRI studies',
    body:
      'You can choose all folder, even if it contains files not related to CT or MRI files. Our Platform will check them and upload only study-related files',
  },
  {
    id: 2,
    header: 'Check the recognition results',
    body:
      'Verify if the Platform mapped the study to the correct body part. If not - just choose the right one. Please consider that not every study has enough data to recognize body part, in this case, the Platform will return “NOT RECOGNIZED” message, so that you could choose the right one manually.',
  },
  {
    id: 3,
    header: 'Safely keep, browse and download your studies',
    body:
      'You can make it in the BODY MODEL screen or utilizing the SCREENINGS table. Please consider that only skeletal layer is engaged right now, so only skeletal-related studies are shown on BODY MODEL, but you still can browse your “none skeletal” studies on the SCREENINGS page.',
  },
];

const CountStage = () =>
  countData.map(item => (
    <CountStageItem
      key={item.id}
      count={item.id}
      stageHeader={item.header}
      stageBody={item.body}
    />
  ));

export default CountStage;
