import React from 'react';

import './index.scss';

const Input = ({
  type,
  placeholder,
  errorMessage,
  errorMessageBackEnd,
  ...props
}) => (
  <div className="inputForm">
    <input
      className="inputForm__input"
      {...props}
      type={type}
      placeholder={placeholder}
    />
    {errorMessage && <span className="inputForm__error">{errorMessage}</span>}
    {errorMessageBackEnd &&
      errorMessageBackEnd.map((item, index) => (
        <div key={index} className="inputForm__error">
          {item}
        </div>
      ))}
  </div>
);
export default Input;
