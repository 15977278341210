import React, { PureComponent } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import GetStarted from './containers/GetStarted/';
import Registration from './containers/Auth/Registration/';
import SignIn from './containers/Auth/SignIn/';
import Profile from './containers/Profile/';
import BodyModel from './containers/BodyModel/';
import Screenings from './containers/Screenings/';
import PrivateRoute from './containers/PrivateRoute';
import ForgotYourPassword from './containers/ForgotYourPassword/';
import ResetPassword from './containers/ResetPassword/';
import CookiePolicy from './components/CookiePolicy/';
import NoMatch from './containers/NoMatch/';
import HeaderAdmin from './components/header/HeaderAdmin';


import PrivacyPolicy from './containers/PrivacyPolicy';
import AboutProject from './containers/AboutProject';



import Footer from './components/footer/';

import { sessionActions } from './redux/actions/sessionActions';

import { ROUT } from './constants/';

class App extends PureComponent {
  componentDidMount() {
    this.props.sessionActions();
  }

  render() {
    const { user } = this.props;
    if (user === null) return null;
    return (
      <main>
        {!!user ? <HeaderAdmin /> : null}
        <Switch>
          <PrivateRoute
            user={!user}
            exact
            path="/"
            component={GetStarted}
            redirectTo={ROUT.profile}
          />
          <PrivateRoute
            user={!user}
            path={ROUT.registration}
            component={Registration}
            redirectTo={ROUT.profile}
          />
          <PrivateRoute
            user={!user}
            path={ROUT.signIn}
            component={SignIn}
            redirectTo={ROUT.profile}
          />
          <PrivateRoute
            user={!user}
            path={ROUT.forgotYourPassword}
            component={ForgotYourPassword}
            redirectTo={ROUT.profile}
          />
          <PrivateRoute
            user={!user}
            path={ROUT.resetPassword}
            component={ResetPassword}
            redirectTo={ROUT.profile}
          />
          <PrivateRoute
            user={user}
            path={ROUT.profile}
            component={Profile}
            redirectTo={ROUT.signIn}
          />
          <PrivateRoute
              user={!user}
              path={ROUT.privacyPolicy}
              component={PrivacyPolicy}
              redirectTo={ROUT.privacyPolicy}
          />

          <PrivateRoute
              user={!user}
              path={ROUT.terms}
              component={AboutProject}
              redirectTo={ROUT.terms}
          />

          <PrivateRoute
            user={user}
            path={ROUT.bodyModel}
            component={BodyModel}
            redirectTo={ROUT.signIn}
          />
          <PrivateRoute
            user={user}
            path={ROUT.screenings}
            component={Screenings}
            redirectTo={ROUT.signIn}
          />
          <Route component={NoMatch} />
        </Switch>
        <Footer />
        <CookiePolicy />
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  sessionActions: () => dispatch(sessionActions()),
});

const mapStateToProps = state => ({
  user: state.sessionActions.user,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
