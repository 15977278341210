import React from 'react';
import Scrollbar from 'react-scrollbars-custom';

import ProgressBackup from '../../../../components/progressBackup/';
import ProfileEditPopUp from '../ProfileEditPopUp/';

const genderUser = user => {
  if (user) {
    const { gender } = user.values;
    switch (gender) {
      case 1:
        return 'MALE';
      case 2:
        return 'FEMALE';
      case 3:
        return 'OTHER';
      default:
        return 'OTHER';
    }
  }
  return null;
};

const userName = user => {
  if (!user) {
    return null;
  }
  if (!user.values) {
    return null;
  }

  const { first_name, last_name } = user.values;

  return `${first_name || ''} ${last_name || ''}`;
};

const userYear = user =>
  user ? user.values.age && `${user.values.age} years old` : null;

const ProfileSetting = ({ profile }) => {
  const { editFormProfile } = profile;

  return (
    <div className="profile__item">
      <h1 className="profile__name">{userName(editFormProfile)}</h1>
      <div className="gender">
        <span className="gender__item gender__item--year">
          {userYear(editFormProfile)}
        </span>
        <span className="gender__item gender__item">
          {genderUser(editFormProfile)}
        </span>
      </div>
      <ProgressBackup />
      <div className="additional">
        <h2 className="additional__title">Additional info</h2>
        <div className="additional__list">
          <Scrollbar style={{ height: 122 }}>
            {editFormProfile ? editFormProfile.values.additional_info : null}
          </Scrollbar>
        </div>
        <ProfileEditPopUp />
      </div>
    </div>
  );
};

export default ProfileSetting;
