import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import ButtonSubmit from '../../components/ButtonSubmit/';
import Button from '../../components/Button/';
import Input from '../../components/Input/';
import Spinner from '../../components/spinner/';
import HederAuth from '../../components/header/HederAuth';

import { forgotPasswordAction } from '../../redux/actions/forgotPasswordAction';

import { requiredEmail } from '../Auth/helpers/';
import { ROUT } from '../../constants/';

import './index.scss';

const renderInputEmail = ({ input, meta, errorMessageBackEnd }) => (
  <Input
    {...input}
    type="E-mail"
    placeholder="E-mail"
    errorMessageBackEnd={errorMessageBackEnd}
    errorMessage={meta.touched && meta.error}
  />
);

let ForgotYourPassword = ({
  handleSubmit,
  valid,
  forgotPasswordAction,
  sendMailToResetState,
  loader,
}) => {
  const onSubmit = async value => {
    console.log('email :', value);
    await forgotPasswordAction(value);
    console.log('sendMailToResetState', sendMailToResetState);
  };

  return (
    <React.Fragment>
      <HederAuth />
      <div className="ForgotYourPassword">
        <h1 className="ForgotYourPassword__title">Forgot your password?</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Field
            name="email"
            component={renderInputEmail}
            validate={requiredEmail}
          />
          <div className="ForgotYourPassword__buttons">
            <Link to={ROUT.signIn}>
              <Button className="Button--initial-text" label="Back" />
            </Link>
            <ButtonSubmit label="send" disabled={!valid} />
          </div>
        </form>
        <Spinner isFetching={loader} />
      </div>
    </React.Fragment>
  );
};

ForgotYourPassword = reduxForm({
  form: 'ForgotYourPassword',
})(ForgotYourPassword);

const mapStateToProps = state => {
  return {
    sendMailToResetState: state.forgotPassword.sendMail,
    loader: state.forgotPassword.loader,
  };
};

const mapDispatchToProps = {
  forgotPasswordAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotYourPassword);
