import {
  FETCH_REGISTER_EXIST_START,
  FETCH_REGISTER_EXIST_SUCCESS,
  FETCH_REGISTER_EXIST_FAILURE,
  FETCH_REGISTER_EXIST_FINALLY,
} from '../actionsTypes';

const initialState = {
  loader: false,
  user: '',
  token: false,
  errors: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REGISTER_EXIST_START:
      return {
        ...state,
        loader: true,
      };

    case FETCH_REGISTER_EXIST_SUCCESS:
      return {
        ...state,
        user: action.payload,
        token: true,
      };
    case FETCH_REGISTER_EXIST_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };

    case FETCH_REGISTER_EXIST_FINALLY:
      return {
        ...state,
        loader: false,
      };

    default:
      return state;
  }
};
