import { STORAGE_TOKEN, STORAGE_TOKEN_CLEAR } from '../actionsTypes';

const initialState = {
  user: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STORAGE_TOKEN:
      return {
        ...state,
        user: true,
      };
    case STORAGE_TOKEN_CLEAR:
      return {
        ...state,
        user: false,
      };
    default:
      return state;
  }
};
