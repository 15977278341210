import { SELECT_TABLE } from '../actionsTypes';

const initialState = {
  select: '',
  id: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_TABLE:
      return {
        ...state,
        select: action.payload.select,
        id: action.payload.id,
      };

    default:
      return state;
  }
};
