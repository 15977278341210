import React from 'react';
import { Link } from 'react-router-dom';

import HederAuth from '../../components/header/HederAuth';

import video from '../../assets/video/bone_reveal2.mp4';

import { ROUT } from '../../constants/';

import './index.scss';

const GetStartedHeader =
    'Advanced Development of Additive Manufacturing';
const GetStartedBody =
    'Technology that has the potential to change the face of medicine';

const Home = ({
                bodyText = GetStartedBody,
                bodyHeader = GetStartedHeader,

              }) => {
  return (
      <>

        <HederAuth text="Sign in" link={ROUT.signIn} />
        <div className="get-started">

          <div className="video--container">

            <video className="get-started__video" playsInline autoPlay muted loop>
              <source src={video} type="video/mp4"></source>
            </video>
          <h1 className="get-started__heading ">{bodyHeader}</h1>
          <p className="get-started__description get-started__h2">{bodyText}</p>

          <div className="get-started__wrapper">
            <Link to={ROUT.registration} className="get-started__btn">
              GET STARTED FREE
            </Link>
            {/*<Link to={ROUT.signIn} className="get-started__btn btn__signIn">*/}
            {/*  SIGN IN*/}
            {/*</Link>*/}
          </div>
          </div>


        </div>


        <section className="get-started--section first--section">
          <div className="text-row row-default row-left">
            <p><span>ADAM PROJECT</span>  is the way to:</p>
            <p> - free people from their bodies;</p>
            <p> - create infrastructure, which can work as the repair shops;</p>
            <p> - turn transplantations from something outstanding to an ordinary process.</p>

            <p className="pt">Through it’s activities <span>ADAM</span> aims to help people to get affordable way to save their lives.</p>



          </div>

          <div className="text-row  row-default row-right row-triple">
           <p><span>The ADAM PROJECT</span>  is aimed at supporting the
            progressive development of tissue engineering.</p>

             


            <p className="pt">Integrated approach will allow 
            to standardize the transplantology process and make
             advanced technologies available for consumers.</p>



          </div>
        </section>
        <section className="get-started--section second--section">
          <div className="text-row row-default row-left">
          <p><span>Strategic ADAM idea</span> - Creating biotech products that substitute human bones  on demand in any part of the world.</p>

            <p className="pt"><span>ADAM</span> sets new standards - opens 
            the way to the future and forever change the transplantation process.</p>

          </div>

          <div className=" alignCenter">
          {/*<p className="pt text--center">Support <span>ADAM</span> for new*/}
          {/*   opportunities for humanity in the future.</p>*/}
          
          {/*   <p className="pt text--center">Sign up for Digital Body Atlas with individual scans of bones, which could be printed on demand</p>*/}
            
              <Link to={ROUT.registration} className="get-started__btn fullwidth">
                GET STARTED FREE
              </Link>
              <p className="pt text--small grey--text">One click <span>ADAM</span>. Unlimited body solutions - Bioprinting human body on demand.</p>
           
          </div>
        </section>


      </>
  );
};

export default Home;
