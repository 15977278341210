import React from 'react';

import './styles.scss';

export const PrivacyHeader = () => {
  return (
    <div className="privacy--header">
      <p className="privacy--header privacy--header__bold">A.D.A.M.</p>
      <p className="privacy--header privacy--header__bold">PRIVACY NOTICE</p>
    </div>
  );
};

export const PrivacyBody = () => {
  return (
    <div className="privacy--body">
      <p className="privacy--date">Effective date: 06 August 2019</p>

      <p>
        This Private Policy explains how information about you is collected,
        used and disclosed by ADVANCEDDEVELOPMENT OF ADDITIVE MANUFACTURING,
        INC., legal entity registered under the laws of the U.S.state of
        Connecticut, Employer Identification Number: 32-0591996, located at: 93
        Shennecossett Road, Groton, CT06340, USA (the "Company"). The Company is
        an international community of patients,radiologists, medical physicists
        and other medical professionals, who are united with a single the
        mission to solve the crisis of transplant organs by creating human
        organs and bones on demand.
      </p>

      <p>
        The Company provides you (the "User") with access to the online and
        mobile services associated with ADAMProject, including but not limited
        to,
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://adamproject.org"
        >
          {' '}
          http://adamproject.org
        </a>{' '}
        and all associated subdomains (the "Platform"), and the mobile
        application (the "App").
      </p>

      <p>
        Our Privacy Policy is written to be compliant with numerous national and
        international laws and frameworks, including (but not limited to){' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.hhs.gov/hipaa/index.html"
        >
          HIPAA
        </a>
        ,{' '}
        <a target="_blank" rel="noopener noreferrer" href="https://eugdpr.org/">
          GDPR
        </a>{' '}
        and the{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.privacyshield.gov/"
        >
          EU-US Privacy Shield.
        </a>
      </p>

      <p>
        We may occasionally alter privacy policy. In case of amendments, we
        notify you, updating a date on the top of the policy. We recommend you
        to review privacy policy, when you gain access to the Platform, in order
        to stay informed about conditions and procedure of processing your
        personal data, as well about methods to defend their confidentiality.
      </p>

      <p>
        The Company complies with the EU-U.S. Privacy Shield Framework as set
        forth by the U.S. Department of Commerce regarding the collection, use,
        and retention of personal information transferred from the European
        Union and the United Kingdom to the United States.
      </p>

      <p>
        If You are based in the EU or the EEA and you are interacting with the
        Company as a provider of services or goods, or us monitoring your
        behavior, then the related processing of your personal data is governed
        by the General Data Protection Regulation (GDPR) and its implementing
        national laws.
      </p>

      <p>
        The Company commits to cooperate with the panel established by the EU/UK
        data protection authorities (DPAs) and comply with the advice given by
        the panel with regard to data transferred from the EU/UK.
      </p>

      <p className="header">1. PERSONAL DATA. TERMS</p>

      <p>
        <span>A possessor of personal data</span> means a natural or a legal
        person, who defines the purpose of processing personal data, determines
        their structure and procedures of processing, unless otherwise is
        provided by the law.
      </p>

      <p>
        <span>Personal data subject’s consent</span> means any free natural
        person’s expression concerning agreement to the personal data being
        processed according to the formulated purpose of processing.
      </p>

      <p>
        <span>Processing of personal data </span>means any act or set of acts,
        committed entirely or partly in the information (automatic) system
        and/or in the files of personal data, which are related to collecting,
        registration, storing, reservation, adaptation, alteration, renovation,
        using and sharing (distribution, realization, remittal),
        depersonalization, elimination of records about a natural person.
      </p>

      <p>
        <span>Personal data </span>means information or an aggregate of
        information about the natural person, who is or can be specifically
        identified.
      </p>

      <p>
        <span>A disposer of the data </span>basis means a natural or a legal
        person, whom the owner of the data basis entrusted processing the data.
      </p>

      <p>
        <span>Personal data subject </span> means a natural or a legal person,
        whose personal data is processed according to the law.
      </p>

      <p>
        <span>Platform </span>means a set of web pages, websites and/or mobile
        applications, computer programs, content, access to which is gained
        using one of the domain names (addresses):{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://adamproject.org."
        >
          http://adamproject.org.
        </a>
      </p>

      <p className="header">2. PERSONAL DATA SUBJECTS’ RIGHTS</p>

      <p>
        Personal non-property rights to personal data, owned by every natural
        person are integral and inviolable. Personal data subject is entitled:
        <p className="bodyList">
          to be informed about sources of collecting, location of his personal
          data, purpose of the processing, location or a place of residence (or
          temporary residence) of the owner or disposer of personal data or to
          give a mandate concerning gaining this information to the authorized
          persons, except cases, provided by the law;
          <br />
          to gain information about conditions of providing access to the
          personal data, in particular, information about third parties, whom
          such personal data will be imparted;
          <br />
          to have access to his personal data;
          <br />
          to gain a respond, whether his personal data is processed, at the
          latest before thirty calendar days since reception of the require,
          except cases, prescribed by the law, and to gain content of such
          personal data;
          <br />
          to make a motivated claim/complaint to the owner of the personal data
          with objections against processing of personal data;
          <br />
          to make a motivated claim concerning alteration or elimination of his
          personal data to any owner or disposer of the personal data, in case
          of processing this data illegally or inadequately;
          <br />
          to have protection of his/her personal data against illegal processing
          or accidental lose, damaging, connected with intentional hiding,
          non-providing or providing of information untimely, which is
          unreliable or disgraces honor, dignity and business reputation of the
          natural person;
          <br />
          to file a complaint regarding processing of personal data to the
          Representative or to the court;
          <br />
          to resort to means of legal protection in case of breaching
          legislation of protection;
          <br />
          to make disclaimers concerning restricting of the right to processing
          of personal data during while giving the consent;
          <br />
          to withdraw consent to the processing of personal data;
          <br />
          to know the mechanism of automatic processing of personal data;
          <br />
          to have protection against automatized decisions, which has legal
          effects for him.
        </p>
      </p>

      <p className="header">3. INFORMATION COLLECTION</p>

      <p>
        By visiting the Platform, You accept our Privacy Policy and allow the
        Company to collect, use and disclose information about you according to
        the Company's business purposes. In particular, the Company:
      </p>

      <ul className="bodyList">
        <li>
          <p>
            collects, uses and stores the minimum amount of personal data that
            is necessary for one or more legitimate business purposes and to
            comply with legal obligations.
          </p>
        </li>
        <li>
          <p>
            protects personal data through physical and technical security
            measures tailored to the sensitivity of the personal data we hold.
          </p>
        </li>
        <li>
          <p>
            takes reasonable steps to ensure that the collected personal data is
            accurate and up-to-date.
          </p>
        </li>
        <li>
          <p>
            integrates privacy in the design of our activities, the software we
            develop and projects that involve the use of personal data.
          </p>
        </li>
      </ul>

      <p>
        Information about an account: in case you create an account on the
        Platform, we may collect and use information from your account,
        including an e-mail, mobile phone, zip code and another information,
        which you provide in your account, for instance, your sex and date of
        birth. In case you indicate your name or provide any photo during
        signing-up, this information will be publicly published as a part of
        your account.
      </p>

      <p>
        Payment information: when you provide data concerning a method of
        payment, we gain this data and decipher this information, using standard
        sectorial technology.
      </p>

      <p>
        Log files: We register information on using of the Platform, including
        the type of the used device, time of access and Your IP-address.
      </p>

      <p>
        Information about device: we collect information about the device, which
        you use in order to gain access to the Platform (hereinafter referred to
        as – the Devices), including information on device’s computer software,
        hardware, etc.
      </p>

      <p>
        Information about using: we collect information about using of the
        Platform, including your other actions, in case they occur.
      </p>

      <p>
        Information about interests: we collect information about material,
        which you are interested in, which is related to your using of the
        Platform.
      </p>

      <p>Your locations: we collect information about your locations.</p>

      <p>
        We also may gain information from other sources and combine with
        information, which we collect through the Platform.
      </p>

      <p className="header">4. TYPES OF INFORMATION COLLECTED</p>

      <p>
        We recognize personal data as any information related to an identified
        or identifiable individual. Depending on the context of your
        interactions with the Company, We collect and use different types of
        personal data from website visitors, members of Our Society and others
        that interact with Us.
      </p>

      <p>Types of information We collect:</p>

      <ul>
        <li>
          <p>
            <span>Membership information: </span>when applying for a membership
            You will be asked to complete the appropriate form providing your
            contact and professional information. For certain positions You
            should also provide your photograph, bank details, and Your
            authorization to verify your information with the appropriate
            licensing authorities or other relevant entities.
          </p>
        </li>

        <li>
          <p>
            <span>Accounts on our website: </span>All members and customers have
            the possibility to create an online account. Besides the information
            You provide us when becoming a member, we also process Your
            username, passwords, and we log Your activity on the website.
          </p>
        </li>

        <li>
          <p>
            <span>
              Registration to events, conferences and the annual meeting:  
            </span>
            We organize different events and conferences, and usually require
            your contact details and financial details. Additional information
            may be required of registrants seeking additional services such as
            accommodation preferences.
          </p>
        </li>

        <li>
          <p>
            <span>Customer information: </span>We work with a variety of
            non-profit and commercial partners to support Our mission through
            advertising and sponsorship at our Annual Meeting, in journals, and
            other activities and collect information from them in the course of
            our interaction.
          </p>
        </li>

        <li>
          <p>
            <span>Marketing activities:</span>
            e-mail addresses and information about the interaction with Our
            communications (such as IP addresses, opens, click-through data). We
            may also send surveys and collect various responses to these surveys
            which may include contact and professional information. The Company
            uses this information to improve customer service, gather customer
            feedback and gauge interest in new products or services. The Company
            also sends content provided by relevant commercial partners to
            interested members and meeting attendees.
          </p>
        </li>

        <li>
          <p>
            <span>Videos and photos: </span>
            We collect and use videos and photos of members and event attendees
            in publicly available educational and promotional materials. Some of
            them include testimonies of persons who authorized us to share their
            personal experiences.
          </p>
        </li>

        <li>
          <p>
            <span>Information collected automatically: </span>
            there is other information that We collect automatically when you
            visit Our site through the use of cookies or similar technologies,
            such as your IP address, browser type, access times.
          </p>
        </li>
      </ul>

      <p className="header">5. WHY WE PROCESS YOUR PERSONAL DATA</p>

      <p>
        The purposes for which We collect and use Your personal data may vary
        depending on the type of relationship You have with Us, such as if You
        are one of Our members or a website visitor.
      </p>

      <p>See below how We use Your information:</p>

      <ul>
        <li>
          <p>
            We process personal data of applicants to membership for the purpose
            of verifying their credentials to see if they meet the criteria to
            become members and for the purpose of processing the membership fee.
          </p>
        </li>
        <li>
          <p>
            We process personal data of Our members when they log into their
            online accounts for the purpose of managing their membership and
            providing them membership related services.
          </p>
        </li>
        <li>
          <p>
            We process personal data in the context of article/abstract
            submissions for the purpose of publication of cutting-edge research
            in the field of radiology.
          </p>
        </li>
        <li>
          <p>
            We process personal data in the context of our educational content
            to properly award credit and provide evidence to third parties of
            awarded credit.
          </p>
        </li>
        <li>
          <p>
            We process personal data of participants to our events for the
            purpose of organizing those events.
          </p>
        </li>
        <li>
          <p>
            We process personal data in the context of marketing activities for
            the purposes of marketing our services and of communicating with our
            members and customers
          </p>
        </li>
        <li>
          <p>
            We process personal data of our members and customers to better
            discern and serve their needs and improve our products and services
          </p>
        </li>
        <li>
          <p>
            We process personal data in the context of videos and photos that we
            maintain, for the purposes of informing our members and the public
            about our activities
          </p>
        </li>
        <li>
          <p>
            We process personal data collected through cookies placed by our
            website to support the operation of our website and to analyze the
            traffic patterns on our site so as to provide better service to
            visitors and members. See our Cookies Policy for more information.
          </p>
        </li>
        <li>
          <p>
            We process personal data to maintain professional day to day
            business operations with Our partners and vendors
          </p>
        </li>
        <li>
          <p>
            We process personal data (images and video) to promote and inform
            our members and the public about Our activities and events.
          </p>
        </li>
      </ul>

      <p>
        We process personal data (images and video) to promote and inform our
        members and the public about Our activities and events.
      </p>

      <p className="header">6. DISCLOSURES TO THIRD PARTIES</p>

      <p>
        The Company engages third party contractors, service providers, and
        other vendors to help us accomplish Our business objectives. There are
        other circumstances where We are required by law to disclose personal
        data to third parties such as public bodies or judicial authorities.
      </p>

      <p>
        We engage with our agents, representatives, contractors, service
        providers or other third parties for the following services:
      </p>

      <ul>
        <li>
          <p>authorization of credit card transactions,</p>
        </li>
        <li>
          <p>order fulfillment,</p>
        </li>
        <li>
          <p>membership management,</p>
        </li>
        <li>
          <p>Marketing email and print partners,</p>
        </li>
        <li>
          <p>Marketing email and print partners,</p>
        </li>
      </ul>

      <p>
        If the engagement involves the transmission of personal data, we require
        the service provider to treat that data consistent with this Policy. A
        contract to protect the personal data is executed before any data is
        disclosed, if that vendor will process personal data of individuals in
        the EU/EEA on our behalf.
      </p>

      <p>
        The Company may also disclose information in special cases when it has a
        good-faith belief that such action is necessary to: (a) conform to legal
        requirements or comply with legal process; (b) protect and defend our
        rights or property; or (c) act to protect the interests of our users or
        others. Occasionally the Company may be required by law enforcement or
        judicial authorities to provide personally identifiable information to
        the appropriate governmental authorities. We will disclose such
        information upon receipt of a court order or subpoena in cooperation
        with a law enforcement investigation. The Company reserves the right to
        report to law enforcement agencies any activities that we in good faith
        believe to be unlawful.
      </p>

      <p className="header">7. YOUR EUROPEAN PRIVACY RIGHTS</p>

      <p>
        If You reside or otherwise find Yourself in the European Economic Area
        (EEA), the Company is committed to process your personal data lawfully
        and facilitate the exercise of Your rights granted by the European
        General Data Protection regulation (GDPR). You can contact Us at any
        time to discuss your privacy concerns.
      </p>

      <p>
        <span>Legal basis for data collection and use:</span>
      </p>

      <p>
        We only collect and use personal data when there is a fair and legal
        basis for its collection and use, for instance, to meet our legitimate
        interests to send marketing material, to comply with legal obligations
        or when We have your authorization (consent which you provide clicking
        the "I AGREE" button and using the Websitr. See below the legal basis
        for each collection:
      </p>

      <ul>
        <li>
          <p>
            Membership information: necessity to enter into a contract or for
            the performance of a contract and legitimate interest
          </p>
        </li>
        <li>
          <p>
            Registration to events, conferences and the annual meeting:
            necessity to enter into or for the performance of a contract. For
            certain processing of personal data in relation to our events, we
            rely on legitimate interest (e.g. taking photos during the event) to
            advance our mission as a membership organization in providing
            education and information.
          </p>
        </li>
        <li>
          <p>
            Marketing activities: Consent for email marketing; our legitimate
            interests for marketing conducted other than through email or phone
            call;
          </p>
        </li>
        <li>
          <p>
            Business information: necessity to enter into a contract or for the
            performance of a contract;
          </p>
        </li>
        <li>
          <p>
            Videos and photos: Legitimate interest for informational and
            educational activities with consent to use specific images and video
          </p>
        </li>
        <li>
          <p>Information collected automatically: consent.</p>
        </li>
      </ul>

      <p>
        <span>Privacy rights under the European regulation:</span>
      </p>

      <p>
        <span>Transparency and the right to information:</span>
        we provide notice to our members, website users and other third parties
        who interact with us of how we use personal data in our day-to-day
        activities at the time of collecting personal data, or as soon
        thereafter as possible. We also publish this privacy notice for a
        greater transparency.
      </p>

      <p>
        <span>
          Right to access, rectification, restriction of processing, erasure,
          and data portability:
        </span>
        if you are based in the EEA we provide you with access to your own
        personal data. In addition, we will rectify your personal data when it
        is incorrect or inaccurate, and we will ensure the right to erasure,
        portability and to restriction of processing when these rights are not
        incompatible with other legal obligations.
      </p>

      <p>
        <span>Right to object and withdraw consent at any time:</span>
        for all marketing materials, you can opt-out anytime, and free of
        charge. The right to object for other processing activities will be
        balanced to ensure that it is not incompatible with local regulations or
        our legitimate interests.
      </p>

      <p>These requests should be submitted as follows:</p>

      <ul>
        <li>
          <p>
            Opt-out of marketing communications: you can opt-out anytime by
            following the opt-out instructions in our commercial e-mails or
            contacting us. You will still continue to receive emails relevant to
            course registrations or purchases (e.g. registration confirmations
            or purchase receipts) or necessary to your continued membership in
            the Company (e.g. dues notices).
          </p>
        </li>
        <li>
          <p>
            To exercise the rest of your rights: you should send a communication
            in writing to:
          </p>
        </li>
      </ul>
      <p>
        ADVANCED DEVELOPMENT OF ADDITIVE MANUFACTURING, INC. <br />
        EIN: 32-0591996 <br />
        Attention: Privacy Request <br />
        93 Shennecossett Road, <br />
        CT 06340, USA
      </p>

      <p>
        In order to fulfil this request, We may require you provide Us
        information to verify your identity and specify Your request. We will
        attend to Your request in a timely manner within 30 days after receiving
        your request. If for any reason we need to extend this period of time,
        we will contact You.
      </p>

      <p>
        If you are not satisfied with our response, You have the right to lodge
        a complaint with a supervisory authority of Your habitual residence,
        place of work or place of the alleged infringement.
      </p>

      <p className="header">8. COOKIES</p>

      <p>
        We and third parties, whom we collaborate with, may use cookie-files,
        web-beacons, tags, scripts, general local objects as HTML 5 and Flash
        (sometimes called as "flash cookies"), advertising identifiers)
        including mobile identifiers, such as IDFA from Apple or Google
        advertising identifier) and similarly technologies ("Cookies") with
        regard to using of the Website, extraneous websites and mobile
        applications. Cookie files can serve as unique identifiers and be
        installed on your computer, in the mobile application or per e-mails,
        which we send you and on Your web-sites. Cookie files can transmit
        information about you and your using of the Platform, for instance,
        about type of your browser, searching options, IP-address, data, related
        to the advertisement, and date of using the Platform, cookie files may
        be stored or be stored just during a particular session.
      </p>

      <p> We use Cookies on the Website to:</p>

      <ul>
        <li>
          <p>
            organize work of the Website as you suppose to: <br />
            prevent fraud, protect your data against unauthorized parties and
            abide by legal requirements; <br />
            retain information concerning how, in your opinion, the Platform
            should be used better; <br />
            understand, how you use the Platform.
          </p>
        </li>
      </ul>

      <p>
        Cookie-files management. Probably, you can opt out some (but not all)
        Cookies through configurations of your device or browser, but it can
        affect functionality of the Platform. Method of opting Cookies out may
        differ depending on the device or browser, but usually it can be found
        in security options.
      </p>

      <p className="header">9. INFORMATION EXCHANGE THROUGH SOCIAL NETWORKS</p>

      <p>
        {' '}
        The Platform may propose information exchange through the Website, the
        App, social networks and other integrated instruments (Facebook,
        Twitter, Youtube, "Like" – bottoms), that allow to share information
        from the Platform. To gain additional information about purposes, scale
        of data collecting and processing in connection with exchange through
        social nets, please, visit privacy policy of subjects, who render these
        opportunities.
      </p>

      <p className="header">
        10. ADVERTISEMENT AND ANALYTICS OF THE SERVICE RENDERED BY THIRD PARTIES
      </p>

      <p>
        {' '}
        We may allow third parties to render context and other advertising
        announcements and provide with analytical Services, related to the work
        of the Platform. These persons may use different identifiers to collect
        information about using of the program, including Your IP-address,
        MAC-address, identifiers of devices, program software and information
        about gadgets, time zone and information about using, including
        information, set out in the paragraph 1 of this private policy. The
        Company and others may use this information, among other things, to
        assess the popularity of a particular content, to ensure context and
        other advertisements and to better understand how the Platform works.
      </p>

      <p className="header">11. SECURITY</p>

      <p>
        The company takes reasonable measures to protect information about you
        against loss, stealing, incorrect using and unauthorized access,
        alteration and elimination.
      </p>

      <p className="header">12. CONNECTION</p>

      <p>
        You may apply with questions concerning this private policy at any time,
        by sending an e-mail at the following link:{' '}
        <a href="mailto:info@adamproject.org">info@adamproject.org</a>. By
        signing-up on the Platform you automatically you’re your consent with
        gaining distribution of information or advertising nature from the
        Company or/and its partners.
      </p>

      <p className="header">13. STORING OF INFORMATION</p>

      <p>
        {' '}
        The Company will store and use information as much as it is necessary to
        render Services of high quality so long as the Company recognizes such
        using as appropriate.
      </p>
    </div>
  );
};
