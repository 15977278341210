import React from 'react';
import { connect } from 'react-redux';

import Button from '../Button/';
import PopupOver from '../PopupOver/';

import './index.scss';

const onReload = () => window.location.reload();

const ErrorPopup = ({ ...rest }) => {
  return rest.errorPopup ? (
    <PopupOver title="Error" close={false}>
      <div className="ErrorPopup-content">
        <p className="ErrorPopup-content__title">Error loading</p>
        <Button label="Reload" onClick={onReload} />
      </div>
    </PopupOver>
  ) : null;
};

const mapStateToProps = state => {
  return {
    errorPopup: state.errorPopup.popup,
  };
};

export default connect(
  mapStateToProps,
  null
)(ErrorPopup);
