import {
  SCREENINGS_START,
  SCREENINGS_SUCCESS,
  SCREENINGS_FAILURE,
  SCREENINGS_FINALLY,
  SCAN_POPUP_UPLOAD,
  DELETE_STUDY,
} from '../actionsTypes';

const initialState = {
  loader: false,
  dataList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SCREENINGS_START:
      return {
        ...state,
        loader: true,
      };

    case SCREENINGS_SUCCESS:
      return {
        ...state,
        dataList: action.payload,
      };
    case SCREENINGS_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };

    case SCAN_POPUP_UPLOAD:
      const stateOld = state.dataList;
      return {
        ...state,
        dataList: [...action.payload, ...stateOld],
      };

    case DELETE_STUDY:
      const newDataList = state.dataList.filter(
        item => item.id !== action.payload
      );
      return {
        ...state,
        dataList: [...newDataList],
      };

    case SCREENINGS_FINALLY:
      return {
        ...state,
        loader: false,
      };

    default:
      return state;
  }
};
