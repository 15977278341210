import {
  FETCH_SIGNIN_START,
  STORAGE_TOKEN,
  FETCH_SIGNIN_FAILURE,
  FETCH_SIGNIN_FINALLY,
  FETCH_SIGNIN_SUCCESS,
  FETCH_ERROR_ACTION_POPUP,
} from '../actionsTypes';

import { api } from '../../helpers/api';

export const signInActions = param => async dispatch => {
  dispatch({ type: FETCH_SIGNIN_START });

  try {
    const user = await api.fetchSignIn(param).then(response => response);
    localStorage.setItem('JVTToken', user.data.token);
    dispatch({
      type: STORAGE_TOKEN,
      payload: {
        storageToken: true,
      },
    });
    dispatch({
      type: FETCH_SIGNIN_SUCCESS,
      payload: user,
    });
  } catch (error) {
    dispatch({
      type: FETCH_SIGNIN_FAILURE,
      payload: error.response,
      error: true,
    });
    if (error.response.status > 400) {
      dispatch({
        type: FETCH_ERROR_ACTION_POPUP,
      });
    }
  } finally {
    dispatch({
      type: FETCH_SIGNIN_FINALLY,
    });
  }
};
