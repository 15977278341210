import { SELECT_BODY } from '../actionsTypes';

const initialState = {
  select: {
    name: 'SKELETAL',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_BODY:
      return {
        ...state,
        select: action.payload,
      };
    default:
      return state;
  }
};
