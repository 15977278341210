import React from 'react';
import ReactTable from 'react-table';
import { takeRight } from 'lodash';
import Moment from 'moment';
import { Link } from 'react-router-dom';

import Button from '../../../../components/Button/';

import { ROUT } from '../../../../constants/';

const columns = [
  {
    Header: 'Type',
    accessor: 'modality',
    className: 'ReactTable__type',
    headerClassName: 'ReactTable__header ReactTable__header--left',
  },
  {
    id: 'Date',
    Header: 'Date',
    accessor: d => {
      return Moment(d.study_date)
        .local()
        .format('DD-MM-YYYY');
    },
    className: 'ReactTable__text ReactTable__text--center',
  },
  {
    Header: 'Body part',
    accessor: 'display_body_part',
    className: 'ReactTable__text ReactTable__text--right',
    headerClassName: 'ReactTable__header ReactTable__header--right',
  },
];

const ProfileLastStudy = ({ screeningsList }) => {
  const lastStudy = takeRight(screeningsList, 3);

  return (
    <div>
      <h2 className="reseaches__title">Last studies</h2>
      <ReactTable
        data={lastStudy}
        columns={columns}
        showPagination={false}
        pageSize={lastStudy.length}
        noScrollX={true}
        resizable={false}
        noDataText="No studies uploaded"
      />
      <Link to={ROUT.screenings}>
        <Button className="Button--normal" label="All Screenings" />
      </Link>
    </div>
  );
};

export default ProfileLastStudy;
