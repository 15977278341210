import OuterBody from './Outer_Body.png';
import head from './F_Head.png';
import Skeletal_Empty from './F_Skeletal_Empty.png';
import chest from './F_Chest.png';
import l_femur from './F_L_Femur.png';
import l_foot from './F_L_Foot.png';
import l_forearm from './F_L_Forearm.png';
import l_lowerleg from './F_L_LowerLeg.png';
import l_shoulder from './F_L_Shoulder.png';
import l_wrist from './F_L_Wrist.png';
import neck from './F_Neck.png';
import pelvis from './F_Pelvis.png';
import r_femur from './F_R_Femur.png';
import r_foot from './F_R_Foot.png';
import r_forearm from './F_R_Forearm.png';
import r_lowerleg from './F_R_LowerLeg.png';
import r_shoulder from './F_R_Shoulder.png';
import r_wrist from './F_R_Wrist.png';
import spine from './F_Spine.png';

import head_glow from './glow/F_Head_Glow.png';
import chest_glow from './glow/F_Chest_Glow.png';
import l_femur_glow from './glow/F_L_Femur_glow.png';
import l_foot_glow from './glow/F_L_Foot_glow.png';
import l_forearm_glow from './glow/F_L_Forearm_glow.png';
import l_lowerleg_glow from './glow/F_L_LowerLeg_glow.png';
import l_shoulder_glow from './glow/F_L_Shoulder_glow.png';
import l_wrist_glow from './glow/F_L_Wrist_Glow.png';
import neck_glow from './glow/F_Neck_glow.png';
import pelvis_glow from './glow/F_Pelvis_glow.png';
import r_femur_glow from './glow/F_R_Femur_glow.png';
import r_foot_glow from './glow/F_R_Foot_glow.png';
import r_forearm_glow from './glow/F_R_Forearm_glow.png';
import r_lowerleg_glow from './glow/F_R_LowerLeg_glow.png';
import r_shoulder_glow from './glow/F_R_Shoulder_glow.png';
import r_wrist_glow from './glow/F_R_Wrist_Glow.png';
import spine_glow from './glow/F_Spine_glow.png';

export const AvatarImages = {
  OuterBody,
  head,
  Skeletal_Empty,
  chest,
  l_femur,
  l_foot,
  l_forearm,
  l_lowerleg,
  l_shoulder,
  l_wrist,
  neck,
  pelvis,
  r_femur,
  r_foot,
  r_forearm,
  r_lowerleg,
  r_shoulder,
  r_wrist,
  spine,
};

export const AvatarImagesGlow = {
  head_glow,
  chest_glow,
  l_femur_glow,
  l_foot_glow,
  l_forearm_glow,
  l_lowerleg_glow,
  l_shoulder_glow,
  l_wrist_glow,
  neck_glow,
  pelvis_glow,
  r_femur_glow,
  r_foot_glow,
  r_forearm_glow,
  r_lowerleg_glow,
  r_shoulder_glow,
  r_wrist_glow,
  spine_glow,
};
