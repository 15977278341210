import { reducer as formReducer } from 'redux-form';
import { routerReducer } from 'react-router-redux';
import registration from './registration';
import registrationExist from './registrationExist';
import sessionActions from './session';
import signIn from './signIn';
import profile from './profile';
import errorPopup from './errorPopup';
import bodyModel from './bodyModel';
import selectBodyNav from './selectBodyNav';
import screenings from './screenings';
import selectBodyPart from './selectBodyPart';
import forgotPassword from './forgotPassword';
import resetPassword from './resetPassword';

export default {
  form: formReducer,
  registration,
  registrationExist,
  sessionActions,
  signIn,
  profile,
  errorPopup,
  bodyModel,
  selectBodyNav,
  routerReducer,
  screenings,
  selectBodyPart,
  forgotPassword,
  resetPassword,
};
