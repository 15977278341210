import React, { Component } from 'react';
import { connect } from 'react-redux';

import { selectBodyNav } from '../../../../redux/actions/selectBodyActionNav';

const modelNow = [
  {
    id: 1,
    name: 'BODY',
  },
  {
    id: 2,
    name: 'SKELETAL',
  },
];

class Navigation extends Component {
  selectBody = name => {
    this.props.selectBodyNav(name);
  };

  renderModelNow = () =>
    modelNow.map(item => (
      <li key={item.id} onClick={() => this.selectBody(item.name)}>
        {item.name}
      </li>
    ));

  render() {
    return (
      <React.Fragment>
        <ul>{this.renderModelNow()}</ul>
        <ul>
          <li>Muscular</li>
          <li>Circulatory</li>
          <li>Integumentary</li>
          <li>Digestive</li>
          <li>Endocrine</li>
          <li>Immune</li>
          <li>Lymphatic</li>
          <li>Nervous</li>
          <li>Reproductive</li>
          <li>Respiratory</li>
        </ul>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {
  selectBodyNav,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigation);
