import React from 'react';

import './styles.scss';

export const AboutHeader = () => {
  return (
    <div className="about--header">
      <p className="about--header about--header__bold">A.D.A.M.</p>
      <p className="about--header about--header__bold">TERMS AND CONDITIONS</p>
    </div>
  );
};
export const AboutBody = () => {
  return (
    <div className="about--body">
      <p className="about--date">Effective date: 06 August 2019</p>

      <p>
        Welcome to ADAM Proejct. We have developed these Terms and Conditions
        (the "Terms") to detail as clearly as possible the respective rights,
        duties and obligations of ADVANCED DEVELOPMENT OF ADDITIVE
        MANUFACTURING, INC., legal entity registered under the laws of the U.S.
        state of Connecticut, Employer Identification Number: 32-0591996,
        located at: 93 Shennecossett Road, Groton, CT 06340, USA (the "Company")
        and you (the "Client" or "You").
      </p>

      <p>
        These Terms govern Your use of the website of ADAM Project and mobile
        services associated with ADAM Project, including but not limited to,{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://adamproject.org"
        >
          http://adamproject.org
        </a>{' '}
        and all associated subdomains and mobile application (the "Website").
        "You" and "Your" means you as the user of the Website. Please read the
        Terms in full before using Our Website. By using Our Website, You are
        signifying that You agree to be legally bound by these Terms, and Our
        Website’s Privacy Policy (*ADD LINK*). If You do not agree to these
        Terms, you should not use Our Website. We reserve the right to modify
        these Terms at any time without prior notice, and Your use of the
        Website binds You to the changes made. We do occasionally update these
        Terms so please review them from time to time to ensure that you are
        aware of any changes made by Us.
      </p>

      <p>
        By clicking the "I AGREE" option, creating an account or paying for or
        using our Service(s), You (or, if applicable, the entity you are acting
        for or on behalf of) accept that the terms, obligations, rights and
        conditions specified here will form a legally binding agreement (the
        "Agreement") between You and the Company. In particular, you confirm
        that you possess adequate legal capacity according to the applicable
        laws, where this consent was given and agree to your personal data being
        processed under conditions, set out on this Terms of Use and the
        Company's Privacy Policy. In case of being not fully legally capable,
        You hereby confirm, that consent to these rules was given by your
        parent, guardians, who possess full legal capacity and are empowered by
        applicable laws to give such a consent.
      </p>

      <p style={{ textTransform: 'uppercase' }}>
        IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER
        LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND THE
        ENTITY TO ALL TERMS AND CONDITIONS OF THIS AGREEMENT AND, "YOU" AND
        "YOUR" IN THIS AGREEMENT SHALL REFER TO SUCH ENTITY. IF YOU DO NOT HAVE
        SUCH AUTHORITY OR IF YOU DO NOT AGREE TO ALL THE TERMS AND CONDITIONS IN
        THIS AGREEMENT, YOU MAY NOT SELECT THE "I AGREE" BUTTON AND MAY NOT USE
        THE SERVICES.
      </p>

      <p>
        If you do not agree with the terms and conditions below, do not click
        the "I AGREE" option, do not create an account, or use the Services. If
        you do not agree with these terms and conditions in full, you are not
        authorized to use the Services in any fashion for any purpose
        whatsoever.
      </p>

      <p className="header">ACCESS TO WEBSITE</p>

      <p>
        You will be able to access Our Website without having to register any
        details with Us. Access to the Website is permitted on a temporary
        basis. We reserve the right to withdraw, disable or amend access to the
        Website without notice. In addition, We reserve the right to disable
        Your access at any time if, in Our opinion, You have failed to comply
        with any of the provisions of these Terms
      </p>

      <p className="header">USE OF WEBSITE</p>

      <p>
        Our Website, images, data, text, audios, videos, photographs, custom
        graphics, logos, button icons, descriptions and all other material
        provided and the collection and compilation and assembly thereof are the
        exclusive property of the Company and are protected by U.S. and
        international copyright laws. In particular, You provide an unequivocal
        consent for sharing scans of Your Magnetic Resonance Imaging (MRI) and /
        or Computerized Axial Tomography (CATs) among other Website Users, who
        are patients, radiologists, medical physicists and other medical
        professionals.
      </p>

      <p>
        The contents of Our Website may be used for informational purposes only.
        Any other use of Our Website contents, including the reproduction,
        modification, distribution, transmission, republication, display or
        performance of the contents without Our prior written consent is
        expressly forbidden.
      </p>

      <p>
        Any other trademarks or service marks appearing anywhere on Our Website
        are the property of their respective owners. To the extent any product
        name or logo does not appear with a trademark (™) does not constitute a
        waiver of any and all intellectual property rights that the Company or /
        and its affiliate businesses has established in any of its products,
        features, or service names or logos.
      </p>

      <p>
        You may not use, reproduce, modify, transmit, display, publish, sell,
        create derivative works, or distribute by any means, method, or process
        whatsoever, now known or hereafter developed, any content of this
        Website for commercial profit or gain. Nothing in these Terms or on the
        Website shall be construed as conferring any intellectual property or
        other proprietary rights to You. You agree to observe copyright and
        other applicable laws and may not use the content in any manner that
        infringes or violates the rights of any person or entity. We hold all
        rights to the content on this Website.
      </p>

      <p>
        As a condition of Your use of the Website, You will not use the Website,
        or any contents or services, for any purpose that is unlawful in any
        applicable jurisdiction where Our Website is being used, or prohibited
        by these Terms and notices, and You agree to abide by the terms and
        conditions set forth herein. You may not use the Website contents or
        services in any manner that could damage, disable, overburden, or impair
        any of Our equipment or interfere with any other party’s use and
        enjoyment of the Website, or any contents or services. You may not
        attempt to gain access to any portion of the Website, or any of its
        contents or services, other than those for which You are authorized.
      </p>

      <p>
        While every effort is made to ensure the timeliness and accuracy of the
        Website content and services, We make no warranties either express or
        implied, and assume no liability or responsibility for the completeness,
        use, accuracy, reliability, correctness, timeliness or usefulness, of
        any of the results obtained from its use.
      </p>

      <p>
        We make no representation or warranty, express or implied, with respect
        to the content of the Website, or links to other websites, including but
        not limited to accurateness, completeness, correctness, timeliness or
        reliability. We make no representation or warranty regarding the
        merchantability or fitness for a particular purpose or use with respect
        to any content or services whatsoever that may be accessed through this
        Website, or the results to be obtained from using the Website. We make
        no representation or warranty that the Website or content is free from
        defects or viruses. Your use of external links and third-party websites
        is at Your own risk and subject to the terms and conditions of use for
        such links and websites.
      </p>

      <p>
        While every effort is made to ensure that all content provided on the
        Website does not contain computer viruses and/or harmful materials, You
        should take reasonable and appropriate precautions to scan for computer
        viruses or otherwise protect Your computer and you should ensure that
        You have a complete and current backup of the applicable items on Your
        computer. We disclaim any liability for the need for services or
        replacing equipment or data resulting from Your use of the Website.
        While every effort is made to ensure smooth and continuous operation, We
        do not warrant the Website will operate error free.
      </p>

      <p className="header">WEBSITE UPTIME</p>

      <p>
        We take all reasonable steps to ensure that Our Website is available 24
        hours every day, 365 days per year. However, websites do sometimes
        encounter downtime due to server and other technical issues. Therefore,
        We will not be liable if Our Website is unavailable at any time.
      </p>

      <p>
        Our Website may be temporarily unavailable due to issues such as system
        failure, maintenance or repair, or for reasons beyond Our control. Where
        possible, We will try to give You advance warning of maintenance issues,
        but shall not be obliged to do so.
      </p>

      <p className="header">USER CONDUCT</p>

      <p>
        Any material You send or post to our Website, except for personal and/or
        confidential information that relates to You, Your contract with the
        Company or the Company's Privacy Policy, shall be considered
        non-proprietary and non-confidential and will become the property of the
        Company and may be used for the purposes described herein.
      </p>

      <p>
        As regarding the personal data disclosure, please note that you provide
        your consent for such data usage and processing by the Company and, if
        applicable, by its affiliates or/and contractors, by clicking the "I
        AGREE" button.
      </p>

      <p>
        If Your personal data is covered by GDPR, please note that You have the
        right to request from the Company information on which personal data We
        store and the purpose for which We process them. You can also request
        access to and rectification of your personal data as well as the right
        to data portability, if applicable (which means if the legal basis for
        collecting your data is consent). Erasure or restriction of processing
        is only possible if and to the extent the processing of personal data is
        based on consent or legitimate interest.
      </p>

      <p>
        If data processing is based on consent, kindly note that You have the
        right to withdraw Your consent at any time, however, without affecting
        the lawfulness of processing based on consent before its withdrawal.
      </p>

      <p>
        When using Our Website, You shall not post or send to or from the
        Website:
      </p>

      <p>
        content for which You have not obtained all necessary consents; content
        that is discriminatory, obscene, pornographic, defamatory, liable to
        incite racial hatred, in breach of confidentiality or privacy, which may
        cause annoyance or inconvenience to others, which encourages or
        constitutes conduct that would be deemed a criminal offense, give rise
        to a civil liability, or otherwise is contrary to the law in any
        applicable jurisdiction where Our Website is being used; content which
        is harmful in nature including, and without limitation, computer
        viruses, Trojan horses, corrupted data, or other potentially harmful
        software or data.
      </p>

      <p>
        We will fully cooperate with any law enforcement authorities or court
        order requiring Us to disclose the identity or other details or any
        person posting materials to Our Website in breach of this section.
      </p>

      <p>
        You shall not use Our Website while distracted or preoccupied, such as
        when operating a motor vehicle. You should access Our Website only with
        due regard for Your own safety and the safety of others.
      </p>

      <p className="header">THIRD PARTY WEBSITE LINKS</p>
      <p>
        Any links furnished on Our Website may allow You to leave Our Website.
        These third-party websites are not under Our control and We disclaim any
        responsibility for the contents of such linked websites or any link(s)
        contained in any such linked website(s) or any changes or updates to any
        of these websites. We further disclaim any responsibility for any form
        of transmission received from any linked third-party website or
        advertising. If We provide these links to You, it is done simply as a
        convenience to You and inclusion of any link herein shall in no way be
        construed as an endorsement by Us of the website(s).
      </p>

      <p>
        If You would like to link to Our Website, You may only do so on the
        basis that you link to, but do not replicate, any page of Our Website,
        and subject to the following conditions:
      </p>

      <p>
        You do not in any way imply that We are endorsing any services or
        products unless this has been specifically agreed with Us; <br />
        You do not misrepresent Your relationship with Us or present any false
        information about Us; <br />
        You do not link from a website that is not owned by You; and <br />
        Your website does not contain content that is offensive, controversial,
        infringes any intellectual property rights or other rights of any other
        person or does not comply in any way with the law of the United States
        of America.
      </p>

      <p>
        If You choose to link Our Website in breach of this Section, You shall
        fully indemnify Us for any loss or damage suffered as a result of Your
        actions.
      </p>

      <p className="header">DISCLAIMERS</p>

      <p>
        All information on Our Website is of a general nature and is furnished
        for Your knowledge and understanding and as an informational resource
        only. The documents and any related graphics published on this server
        could include technical inaccuracies or typographical errors. Changes
        are periodically added to the information contained on Our Website. We
        may make improvements and/or changes to any of the products depicted or
        described herein at any time. We expressly disclaim any responsibility
        or liability for any adverse consequences or damages resulting from Your
        use of the Website or reliance on the information from either.
      </p>

      <p>
        THE WEBSITE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. WE MAKE
        NO WARRANTIES, EXPRESS OR IMPLIED, REPRESENTATIONS OR ENDORSEMENTS
        WHATSOEVER WITH REGARD TO ANY PRODUCTS, INFORMATION OR SERVICE PROVIDED
        THROUGH THIS WEBSITE, INCLUDING, TITLE, NON-INFRINGEMENT, OR ANY OTHER
        WARRANTY, CONDITION, GUARANTY, OR REPRESENTATION, WHETHER ORAL OR IN
        WRITING, OR IN ELECTRONIC FORM.
      </p>

      <p className="header">LIMITATION OF LIABILITY</p>

      <p>
        Your use of Our Website is entirely at your own risk. We will not be
        responsible to you or to any third parties for any direct or indirect,
        incidental, consequential, special or punitive damages or losses or
        injury you may incur in connection with your use or inability to use the
        website or the products, or your reliance on our use of the website, the
        information, or services provided, or that result from mistakes,
        omissions, interruptions, deletions or any failure of performance, or
        any of the data or the materials transmitted through or residing on our
        website, regardless of the type of claim or the nature of the cause of
        action, even if we have advised of the possibility of such damage or
        loss.
      </p>

      <p className="header">INDEMNIFICATION</p>

      <p>
        You agree to defend, indemnify, and hold Us, Our officers, directors,
        employees, volunteers, agents, licensors, and suppliers, harmless from
        and against any claims, actions or demands, liabilities and settlements
        including without limitation, reasonable legal and accounting fees,
        resulting from or alleged to result from, Your use of and access to the
        Website or Your violation of these Terms or Your violation of any third
        party right, including without limitation any trademark, copyright or
        other proprietary or privacy right.
      </p>

      <p className="header">ASSIGNMENT</p>

      <p>
        These Terms, and any rights and licenses granted hereunder, may not be
        transferred or assigned by You.
      </p>

      <p className="header">APPLICABLE LAW</p>

      <p>
        We make no representations that the content or the Website are
        appropriate or may be used or downloaded outside the United States.
        Access to the Website and/or the content may not be legal in certain
        countries outside the United States. If You access the Website from
        outside the United States, You do so at Your own risk and are
        responsible for compliance with the laws of the jurisdiction from which
        You access the Website. The Website, and its contents, and any disputes
        arising therefrom shall be construed and interpreted under the laws of
        the State of Delaware and applicable United States federal laws. Use of
        Our Website constitutes Your agreement to the jurisdiction of the state
        and federal courts located therein.
      </p>
      <p>
        Our Website is subject to the United States export control laws and
        regulations, and may be subject to export or import regulations in other
        countries. You agree to strictly comply with all such laws and
        regulations and acknowledge that You have the responsibility to obtain
        authorization to export, re-export, or import of any data on Our
        Website, as may be required.
      </p>

      <p className="header">GENERAL</p>

      <p>
        These Terms, together with the Privacy Policy, and any legal notices
        published by Us on the Website, shall constitute the entire agreement
        between us concerning use of the Website. If any provision of these
        Terms is deemed invalid by a court of competent jurisdiction, the
        invalidity of such provision shall not affect the validity of the
        remaining provisions of these Terms, which shall remain in full force
        and effect. No waiver of any term shall be deemed a further or
        continuing waiver of such term or any other term, and Our failure to
        assert any right or provision under these Terms shall not constitute a
        waiver of such right or provision.
      </p>

      <p>
        YOU AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE
        WEBSITE MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION
        ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
      </p>

      <p className="header">OUR CONTACT INFORMATION</p>

      <p>
        If You have any queries or concerns about these Terms or material that
        appears on Our Website please contact us at ________________.
      </p>
    </div>
  );
};
