import {
  RESET_MAIL_SUCCESS,
  RESET_MAIL_FAILURE,
  // FETCH_ERROR_ACTION_POPUP,
  RESET_MAIL_FINALLY,
  RESET_MAIL_START,
} from '../actionsTypes';

import { api } from '../../helpers/api';

export const forgotPasswordAction = data => async dispatch => {
  dispatch({
    type: RESET_MAIL_START,
  });

  console.log('object', data);

  try {
    const sendMail = await api
      .fetchForgotPassword(data)
      .then(response => response);

    dispatch({
      type: RESET_MAIL_SUCCESS,
      payload: sendMail.data,
    });
  } catch (error) {
    const message = error.response;
    dispatch({
      type: RESET_MAIL_FAILURE,
      payload: message,
      error: true,
    });

    // if (error.response.status > 400) {
    //   dispatch({
    //     type: FETCH_ERROR_ACTION_POPUP
    //   })
    // }
  } finally {
    dispatch({
      type: RESET_MAIL_FINALLY,
    });
  }
};
