import {
  FETCH_REGISTER_EXIST_START,
  FETCH_REGISTER_EXIST_SUCCESS,
  FETCH_REGISTER_EXIST_FAILURE,
  FETCH_REGISTER_EXIST_FINALLY,
  FETCH_ERROR_ACTION_POPUP,
} from '../actionsTypes';

import { api } from '../../helpers/api';

export const existUser = param => async dispatch => {
  dispatch({ type: FETCH_REGISTER_EXIST_START });

  try {
    const user = await api
      .fetchExistRegistration(param)
      .then(response => response);
    dispatch({
      type: FETCH_REGISTER_EXIST_SUCCESS,
      payload: user,
    });
  } catch (error) {
    const message = error.response;
    dispatch({
      type: FETCH_REGISTER_EXIST_FAILURE,
      payload: message,
      error: true,
    });

    if (error.response.status > 400) {
      dispatch({
        type: FETCH_ERROR_ACTION_POPUP,
      });
    }
  } finally {
    dispatch({
      type: FETCH_REGISTER_EXIST_FINALLY,
    });
  }
};
