// eslint-disable-next-line
export const requiredEmail = mail =>
  mail && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(mail)
    ? "That format doesn't look right.Make sure there aren't any typos."
    : null;
export const required = value => (value ? undefined : 'This field is required');
export const passNumber = val =>
  val.length > 10 ? 'Invalid phone number, must be 10 digits' : null;
export const minLength = len => value =>
  value && value.length < len ? `Must have greater than ${len}` : undefined;

export const tooOld = value =>
  value && value > 200 ? 'You might be too old for this' : undefined;
export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined;
export const minValue8 = minValue(8);
export const number = value =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined;

export const onlyNumbers = password =>
  (password && !isNaN(+password)) || (password && /^[a-zA-Z]+$/.test(password))
    ? "For your data's security maximization, please use both numbers and letters for the password."
    : null;
