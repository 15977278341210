import React from 'react';

import './index.scss';

const Checkbox = ({
  label,
  id,
  name,
  value,
  checked,
  disabled,
  type = 'checkbox',
  ...props
}) => {
  return (
    <>
      <input
        {...props}
        className="styled-checkbox"
        id={`styled-checkbox-${id}`}
        type={type}
        value={value}
        name={name}
        checked={checked}
        disabled={disabled}
      />
      <label htmlFor={`styled-checkbox-${id}`}>{label}</label>
    </>
  );
};

export default Checkbox;
