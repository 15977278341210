import {
  BODY_MODEL_START,
  BODY_MODEL_SUCCESS,
  BODY_MODEL_FAILURE,
  BODY_MODEL_FINALLY,
} from '../actionsTypes';

const initialState = {
  body: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BODY_MODEL_START:
      return {
        ...state,
      };

    case BODY_MODEL_SUCCESS:
      return {
        ...state,
        body: action.payload,
      };
    case BODY_MODEL_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };

    case BODY_MODEL_FINALLY:
      return {
        ...state,
      };

    default:
      return state;
  }
};
