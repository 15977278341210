import React, { Component } from 'react';

import ConfirmationPopup from '../ConfirmationPopup/';

import './index.scss';

class Logout extends Component {
  state = {
    logOutPopup: false,
  };

  onTogglePopup = () => {
    this.setState({
      logOutPopup: !this.state.logOutPopup,
    });
  };

  Logout = () => {
    localStorage.removeItem('JVTToken');
    window.location.reload();
  };

  render() {
    const { className } = this.props;
    const { logOutPopup } = this.state;
    return (
      <>
        <span className={className} onClick={this.onTogglePopup}>
          Logout
        </span>
        <ConfirmationPopup
          togglePopup={logOutPopup}
          onClickAction={this.Logout}
          labelButton="LOGOUT"
          textPopup="Are you sure you want to log out?"
          titlePopup="LOGOUT"
          onClose={this.onTogglePopup}
        />
      </>
    );
  }
}

export default Logout;
