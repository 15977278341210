import {
  PROFILE_START,
  PROFILE_SUCCESS,
  PROFILE_FAILURE,
  PROFILE_FINALLY,
} from '../actionsTypes';

const initialState = {
  loader: false,
  user: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_START:
      return {
        ...state,
        loader: true,
      };

    case PROFILE_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    case PROFILE_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };

    case PROFILE_FINALLY:
      return {
        ...state,
        loader: false,
      };

    default:
      return state;
  }
};
