import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import BackgroundPage from '../../components/BackgroundPage/';
import Spinner from '../../components/spinner/';
import UploadScansPopup from '../../components/UploadScansPopup/';
import CountStage from './components/CountStage/';
import Avatar from './components/Avatar/';
import Navigation from './components/Navigation/';

import background from '../../assets/img/BODY_MODEL.jpg';
import Human_ADAM from '../../assets/img/Main_l.png';
import Human_EVA from '../../assets/img/Human_EVA.png';

import './index.scss';

const BodyModel = ({ user, loader, bodyModelData }) => {
  const study = bodyModelData && isEmpty(bodyModelData.body);

  return (
      <React.Fragment>
        <BackgroundPage background={background} />
        <div className="BodyModel">
          <div className="BodyModel__body">
            <div className="BodyModel__item BodyModel__body_text">
              <CountStage />
            </div>
            <div className="BodyModel__item BodyModel__body_model">
              {study ? (
                  <img
                      className="BodyModel__img"
                      src={user && user.gender === 2 ? Human_EVA : Human_ADAM}
                      alt="Human ADAM"
                  />
              ) : (
                  <Avatar />
              )}
              <div className="BodyModel__item BodyModel__body_modelPopButton">
                <UploadScansPopup positionBtn={study ? '' : 'right'} />
              </div>
            </div>
            <div className="BodyModel__body_settings">
              <Navigation />
            </div>
          </div>
        </div>
        <Spinner isFetching={loader} />
      </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    profile: state.form,
    user: state.profile.user,
    loader: state.profile.loader,
    bodyModelData: state.bodyModel,
  };
};

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(BodyModel)
);
