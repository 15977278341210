import React from 'react';
import ReactTable from 'react-table';

import './index.scss';

const data = [
  {
    type: 'Cookies authentications files',
    purpose:
      'These cookies (including local storage and similar technologies) are essential to enable your use of the site or services, such as assisting with your account login, so we can show you the appropriate experience and features such as your account information, trip history, and to edit your account settings. They may also provide authentication, site security, and help localize the language based on the geography of your visit.',
    serves: 'ADVANCED DEVELOPMENT OF ADDITIVE MANUFACTURING, INC',
  },
  {
    type: 'Site features and Services',
    purpose:
      'These provide functionality that help us deliver products and Services. For example, cookies help you log in by pre-filling fields. We may also use cookies and similar technologies to help us provide you and others with social plugins and other customized content and experiences, such as making suggestions to you and others.',
    serves: 'ADVANCED DEVELOPMENT OF ADDITIVE MANUFACTURING, INC.',
  },
  {
    type: 'Analytics, performance and research',
    purpose:
      "These are used to understand, improve, and research products and Services, including when you access the Company's website and related websites and apps from a computer or mobile device.For example, we may use cookies to understand how you are using site features, and segmenting audiences for feature testing.Our partners and we may use these technologies and the information we receive to improve and understand how you use websites, apps, products, services and ads.",
    serves: 'ADVANCED DEVELOPMENT OF ADDITIVE MANUFACTURING, INC.',
  },
  {
    type: 'Advertising',
    purpose:
      'These cookies and pixels are used to deliver relevant ads, track email marketing or ad campaign performance and efficiency. For example, our ad partners and we may rely on information gleaned through these cookies to serve you ads that may be interesting to you on other websites. Similarly, our partners may use a cookie, attribution service or another similar technology to determine whether we have served an ad and how it performed or provide us with information about how you interact with them.',
    serves:
      'ADVANCED DEVELOPMENT OF ADDITIVE MANUFACTURING, INC ·	Facebook ·	Twitter ·	Google',
  },
];

const columns = [
  {
    Header: 'Type of Cookie',
    accessor: 'type',
  },
  {
    Header: 'Purpose',
    accessor: 'purpose',
    minWidth: 200,
  },
  {
    Header: 'Who Serves (for example)',
    accessor: 'serves',
  },
];

const InfoPolicy = () => {
  return (
    <div className="InfoPolicy">
      <p className="InfoPolicy__text InfoPolicy__text--right">
        Effective Date: 06 August 2019
      </p>

      <p>
        ADVANCED DEVELOPMENT OF ADDITIVE MANUFACTURING, INC., legal entity
        registered under the laws of the U.S. state of Connecticut, Employer
        Identification Number: 32-0591996, located at: 93 Shennecossett Road,
        Groton, CT 06340, USA (the "Company" or "We") and our affiliates, third
        parties, and other partners use cookies and other identification
        technologies on our websites, mobile applications, email communications,
        advertisements, and other online services (collectively, the "Services")
        for a number of purposes, including: authenticating users, remembering
        user preferences and settings, determining the popularity of content,
        delivering and measuring the effectiveness of advertising campaigns,
        analyzing site traffic and trends, and generally understanding the
        online behaviors and interests of people who interact with our Services.
        You can read more here about the types of cookies we use, why we use
        them, and how you can exercise your choices.
      </p>
      <h2>Cookies and Related Technologies Overview</h2>
      <p>
        Cookies are small text files that are stored on your browser or device
        by websites, apps, online media, and advertisements that are used to
        remember your browser or device during and across website visits. We
        also utilize other technologies that may identify you or the devices you
        use. For example, "pixel tags" (also called beacons) are small blocks of
        code installed on (or called by) a webpage, app, or advertisement which
        can retrieve certain information about your device and browser,
        including for example: device type, operating system, browser type and
        version, website visited, time of visit, referring website, IP address,
        advertising identifiers, and other similar information, including the
        small text file (the cookie) that uniquely identifies the device. Pixels
        provide the means by which third parties can set and read browser
        cookies from a domain that they do not themselves operate and collect
        information about visitors to that domain, typically with the permission
        of the domain owner. “Local storage” refers generally to other places on
        a browser or device where websites, ads, or third parties (such as HTML5
        local storage and browser cache) can store information. “Software
        Development Kits” (also called SDKs) function like pixels and cookies,
        but operate in the mobile app context where pixels and cookies cannot
        always function. The primary app developer can install pieces of code
        (the SDK) from partners in the app, and thereby allow the partner to
        collect certain information about user interaction with the app and
        information about the user device and network information.
      </p>
      <h2>Advertising Synchronization & Relevancy</h2>
      <p>
        In order to facilitate the most relevant ads possible, the Company works
        with various service providers who assist us in delivering similar ads
        to end users across devices and platforms. For example, we work with
        social media advertising services to provide you with relevant ads based
        on your activity through their media channels. We may also use service
        providers to provide you with a similar ad on a mobile website or mobile
        application as with a traditional website ad. See below for more
        information on your choices to limit these types of advertising.
      </p>

      <h2>Your Choices</h2>
      <p>
        You have the right to choose whether to accept cookies. However, they
        are an important part of how our Services work, so you should be aware
        that if you choose to refuse or remove cookies, this could affect the
        availability and functionality of the Services.
      </p>
      <p>
        Most web browsers are set to accept cookies by default. If you prefer,
        you can usually choose to set your browser to remove or reject browser
        cookies. To do so, please follow the instructions provided by your
        browser that are usually located within the "Help" or “Preferences”
        menu. Some third parties also provide the ability to refuse their
        cookies directly by clicking on an opt-out link, and we have indicated
        where this is possible in the table below.
      </p>
      <p>
        Removing or rejecting browser cookies does not necessarily affect
        third-party flash cookies, which may be used by us or our partners in
        connection with our Services.
      </p>
      <p>
        To delete or disable flash cookies please visit this site for more
        information. For further information about cookies, including how to see
        what cookies have been set on your device and how to manage and delete
        them, you can visit{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://youradchoices.com"
        >
          https://youradchoices.com
        </a>{' '}
        and{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.youronlinechoices.eu/"
        >
          www.youronlinechoices.eu
        </a>{' '}
        for EU visitors.
      </p>
      <p>
        For mobile users, you have controls in your device Operating System that
        enables you to choose whether to allow cookies, or share your
        advertising ID with companies like the Company or our advertising
        service providers. For information on controlling your mobile choices,
        you can visit{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.networkadvertising.org/mobile-choices"
        >
          www.networkadvertising.org/mobile-choices
        </a>
        .
      </p>
      <h2>Types and Purposes of Cookies</h2>
      <p>
        The following table sets out the different categories of cookies that
        our Services use and why we use them. The lists of third party cookie
        providers are intended merely as illustrative and should not be viewed
        as a comprehensive list.
      </p>

      <ReactTable
        data={data}
        columns={columns}
        pageSize={data.length}
        resizable={false}
        filterable={false}
        showPagination={false}
      />
    </div>
  );
};

export default InfoPolicy;
