import {
  PROFILE_START,
  PROFILE_SUCCESS,
  PROFILE_FAILURE,
  PROFILE_FINALLY,
  FETCH_ERROR_ACTION_POPUP,
} from '../actionsTypes';

import { api } from '../../helpers/api';

export const profileAction = () => async dispatch => {
  dispatch({ type: PROFILE_START });

  try {
    const profile = await api.fetchProfile().then(response => response);

    dispatch({
      type: PROFILE_SUCCESS,
      payload: profile.data,
    });
  } catch (error) {
    const message = error.response;
    dispatch({
      type: PROFILE_FAILURE,
      payload: message,
      error: true,
    });

    if (error.response.status > 400) {
      dispatch({
        type: FETCH_ERROR_ACTION_POPUP,
      });
    }
  } finally {
    dispatch({
      type: PROFILE_FINALLY,
    });
  }
};
