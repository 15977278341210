import React, { Component } from 'react';
import { connect } from 'react-redux';
import Scrollbar from 'react-scrollbars-custom';
import ReactTable from 'react-table';
import Moment from 'moment';

import { ArrowTop, ArrowBottom } from '../../../../components/Arrow/';
import ConfirmationPopup from '../../../../components/ConfirmationPopup/';
import Spinner from '../../../../components/spinner/';

import { screeningsDeleteAction } from '../../../../redux/actions/screeningsDeleteAction';
import { screeningsAction } from '../../../../redux/actions/screeningsAction';
import { bodyModelAction } from '../../../../redux/actions/bodyModelAction';
import { api } from '../../../../helpers/api';

const RowArrowIcon = ({ elem }) => {
  return <div className="rowArrowIcon">{elem}</div>;
};

class Table extends Component {
  state = {
    deletePopup: false,
    idStudy: null,
    loaderAction: false,
    search: '',
  };

  toggleLoaderAction = () =>
    this.setState(prevState => ({ loaderAction: !prevState.loaderAction }));

  onRefresh = async () => {
    const { screeningsAction } = this.props;
    this.toggleLoaderAction();
    await screeningsAction();
    this.toggleLoaderAction();
  };

  onDelete = ({ id }) => {
    this.setState({
      deletePopup: true,
      idStudy: id,
    });
  };

  onClosePopup = () => {
    this.setState({
      deletePopup: false,
    });
  };

  renderLink = ({ download_link }) => {
    return download_link ? (
      <a
        className="ReactTable__btn download__btn"
        target="_blank"
        rel="noopener noreferrer"
        href={download_link}
      >
        Download
      </a>
    ) : (
      <button className="ReactTable__btn refresh__btn" onClick={this.onRefresh}>
        Refresh
      </button>
    );
  };

  handleDelete = async () => {
    const { screeningsDeleteAction, bodyModelAction } = this.props;
    const { idStudy } = this.state;

    try {
      this.onClosePopup();
      this.toggleLoaderAction();
      await api.fetchDeleteStudy(idStudy);
      screeningsDeleteAction(idStudy);
      bodyModelAction();
    } catch (error) {
      console.log(error);
    } finally {
      this.toggleLoaderAction();
    }
  };

  columns = () => [
    {
      Header: '',
      expander: true,
      Expander: ({ isExpanded, ...rest }) =>
        isExpanded ? (
          <RowArrowIcon elem={<ArrowTop />} />
        ) : (
          <RowArrowIcon elem={<ArrowBottom />} />
        ),
      className: 'arrowColumn',
      headerClassName: 'arrowHeader headerText',
    },
    {
      Header: 'Type',
      accessor: 'modality',
      className: 'ReactTable__type bodyText',
      headerClassName: 'ReactTable__type headerText',
    },
    {
      id: 'Date',
      Header: 'Date',
      accessor: d => {
        return Moment(d.study_date)
          .local()
          .format('L');
      },
      className: 'ReactTable__date',
      headerClassName: 'ReactTable__date headerText',
    },
    {
      Header: 'Body part',
      className: 'ReactTable__bodypart',
      headerClassName: 'headerText ReactTable__bodypart',
      id: 'Body part',
      accessor: 'display_body_part',
    },
    {
      id: 'Description',
      Header: 'Description',
      accessor: d => {
        return (
          <div className="ReactTable__description">
            <p>
              <span className="ReactTable__details">Body part examined: </span>{' '}
              {d.body_part_examined}
            </p>
            <p>
              <span className="ReactTable__details">Institution Name: </span>{' '}
              {d.institution_name}
            </p>
            <p>
              <span className="ReactTable__details">Station Name: </span>{' '}
              {d.station_name}
            </p>
            <p>
              <span className="ReactTable__details"> Study Description: </span>{' '}
              {d.study_description}
            </p>
          </div>
        );
      },
      minWidth: 200,
      className: 'ReactTable__description',
      headerClassName:
        'ReactTable__description ReactTable__header ReactTable__header--left headerText',
    },
    {
      Header: '',
      className: 'ReactTable__button',
      headerClassName: 'ReactTable__button',
      Cell: row => this.renderLink(row.original),
    },
    {
      Header: '',
      className: 'ReactTable__button',
      headerClassName: 'ReactTable__button',
      Cell: row => (
        <button
          className="ReactTable__btn  delete__btn"
          onClick={() => this.onDelete(row.original)}
        >
          Delete
        </button>
      ),
    },
  ];

  handleRef = ref => {
    console.log(ref);
    this.row = ref;
  };

  onSearch = e => this.setState({ search: e.target.value });

  render() {
    const { loader, screeningsList } = this.props;
    const { deletePopup, loaderAction, search } = this.state;

    let data = screeningsList;
    if (search) {
      const lowerCaseSearch = search.toLowerCase();
      data = data.filter(row => {
        return (
          String(row.body_part_examined)
            .toLowerCase()
            .includes(lowerCaseSearch) ||
          String(row.display_body_part)
            .toLowerCase()
            .includes(lowerCaseSearch) ||
          String(row.modality)
            .toLowerCase()
            .includes(lowerCaseSearch) ||
          String(row.institution_name)
            .toLowerCase()
            .includes(lowerCaseSearch) ||
          String(row.station_name)
            .toLowerCase()
            .includes(lowerCaseSearch) ||
          String(row.study_description)
            .toLowerCase()
            .includes(lowerCaseSearch) ||
          Moment(row.study_date)
            .local()
            .format('DD-MM-YYYY')
            .includes(lowerCaseSearch)
        );
      });
    }

    return (
      <React.Fragment>
        <input
          className="Screenings__search"
          value={search}
          onChange={this.onSearch}
        />
        <ReactTable
          TbodyComponent={({ children }) => {
            return (
              <Scrollbar maximalThumbYSize={77} className="Screenings__table">
                {children}
              </Scrollbar>
            );
          }}
          data={data}
          columns={this.columns()}
          showPagination={false}
          pageSize={data.length}
          noScrollX={true}
          resizable={false}
          SubComponent={({ original }) => {
            return (
              <div
                className="ReactTable__description ReactTable__description--expander"
                ref={this.handleRef}
              >
                <p>
                  <span className="ReactTable__details">Manufacturer:</span>{' '}
                  {original.manufacturer}
                </p>
                <p>
                  <span className="ReactTable__details">
                    Institution Address:
                  </span>{' '}
                  {original.institution_address}
                </p>
                <p>
                  <span className="ReactTable__details">Patient's Name:</span>{' '}
                  {original.patient_name}
                </p>
                <p>
                  <span className="ReactTable__details">Patient's Age:</span>{' '}
                  {original.patient_age}
                </p>
                <p>
                  <span className="ReactTable__details">Slice Thickness:</span>{' '}
                  {original.slice_thickness}
                </p>
                <p>
                  <span className="ReactTable__details"> Slides:</span>
                  {original.files_count}
                </p>
                <p>
                  <span className="ReactTable__details"> Size:</span>
                  {original.archive_size}
                </p>
              </div>
            );
          }}
          noDataText="No studies uploaded"
        />
        <ConfirmationPopup
          togglePopup={deletePopup}
          onClickAction={this.handleDelete}
          labelButton="DELETE"
          textPopup={
            <div>
              Please, pay your attention! There is no way to recover data after
              deletion. <br />
              <br />A you sure you want to delete this study forever?
            </div>
          }
          titlePopup="delete"
          onClose={this.onClosePopup}
        />
        <Spinner isFetching={loaderAction} />
        <Spinner isFetching={loader} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    loader: state.screenings.loader,
    screeningsList: state.screenings.dataList,
  };
};

const mapDispatchToProps = {
  screeningsDeleteAction,
  screeningsAction,
  bodyModelAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Table);
