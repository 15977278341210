import React, { Component } from 'react';

class ErrorBoundary extends Component {
  state = { error: null, errorInfo: '', hasError: false };

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
      hasError: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return <h1>{this.state.errorInfo}</h1>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
