import React from 'react';
import { connect } from 'react-redux';

import BackgroundPage from '../../components/BackgroundPage/';
import UploadScansPopup from '../../components/UploadScansPopup/';
import ProfileSetting from './components/ProfileSetting/';
import ProfileLastStudy from './components/ProfileLastStudy/';

import background from '../../assets/img/profile.jpg';
import Human_ADAM from '../../assets/img/Main_l.png';
import Human_EVA from '../../assets/img/Human_EVA.png';
import './index.scss';

const Profile = ({ profileForm, profile, screeningsList }) => {
  return (
    <React.Fragment>
      <BackgroundPage background={background} />
      <div className="profile">
        <ProfileSetting profile={profileForm} />
        <div className="profile__item profile__item--image">
          <div className="profile__img">
            <img
              src={
                profile.user && profile.user.gender === 2
                  ? Human_EVA
                  : Human_ADAM
              }
              alt="Human ADAM"
            />
          </div>
          <UploadScansPopup positionBtn="static" />
        </div>
        <div className="profile__item reseaches">
          <ProfileLastStudy screeningsList={screeningsList} />
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    profileForm: state.form,
    profile: state.profile,
    loader: state.profile.loader,
    screeningsList: state.screenings.dataList,
  };
};

export default connect(
  mapStateToProps,
  null
)(Profile);
