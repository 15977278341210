import React from 'react';

import './index.scss';

const Button = ({ label, className = '', disabled = false, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className={`Button ${className}`}
    disabled={disabled}
  >
    {label}
  </button>
);

export default Button;
