import React, { Component } from 'react';

import Button from '../Button/';
import PopupOver from '../PopupOver/';
import InfoPolicy from './components/InfoPolicy/';
import { CloseIcon } from '../CloseIcon/';

import './index.scss';

const cookieName = 'onConfirmPopup';

const setDataCookie = (year, name, value) => {
  const data = new Date();
  data.setDate(data.getDate() + year);
  document.cookie =
    name + '=' + value + '; path=/; expires=' + data.toUTCString();
};

const getCookie = name => {
  const matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        name.replace(/([$?*|{}[\]^])/g, '\\$1') +
        '=([^;]*)'
    )
  );
  return parseInt(matches ? decodeURIComponent(matches[1]) : undefined);
};

class CookiePolicy extends Component {
  state = {
    popupCookiePolicy: false,
    popupInfo: false,
  };

  componentDidMount() {
    if (!getCookie(cookieName)) {
      this.toggleStateCookiePopup();
    }
  }

  toggleStateCookiePopup = () =>
    this.setState({ popupCookiePolicy: !this.state.popupCookiePolicy });
  toggleStatePopupInfo = () =>
    this.setState({ popupInfo: !this.state.popupInfo });

  onConfirm = () => {
    setDataCookie(365, cookieName, 1);
    this.toggleStateCookiePopup();
  };

  onOpenPopup = () => {
    this.toggleStatePopupInfo();
  };
  render() {
    const { popupCookiePolicy, popupInfo } = this.state;

    return popupCookiePolicy ? (
      <div className="CookiePolicy">
        <div className="CookiePolicy__item">
          <h2 className="CookiePolicy__heading">A.D.A.M. COOKIE POLICY</h2>
          <p>
            We use cookies and similar technologies to recognize your repeat
            visits and preferences, as well as to measure the effectiveness of
            campaigns and analyze traffic. To learn more about cookies,
            including how to disable them, view our{' '}
            <span className="CookiePolicy__btn" onClick={this.onOpenPopup}>
              Cookie Policy.
            </span>{' '}
            <br />
            By clicking “I Accept” or “X” on this banner, or using our site, you
            consent to the use of cookies unless you have disabled them.
          </p>
        </div>
        <div className="CookiePolicy__item CookiePolicy__item--btn">
          <Button
            className="Button--normal"
            label="I Accept"
            onClick={this.onConfirm}
          />
          <span className="CookiePolicy__close" onClick={this.onConfirm}>
            <CloseIcon />
          </span>
        </div>
        {popupInfo ? (
          <PopupOver
            title="A.D.A.M. COOKIE POLICY"
            onClose={this.toggleStatePopupInfo}
          >
            <InfoPolicy />
          </PopupOver>
        ) : null}
      </div>
    ) : null;
  }
}

export default CookiePolicy;
