import axios, { CancelToken } from 'axios';

const ROOT_URL = 'https://adam-be-test.herokuapp.com/';
// const ROOT_URL = 'http://10.8.26.129:3000/';

const URL_AUTH = `${ROOT_URL}rest-auth/registration`;
const URL_SIGNIN = `${ROOT_URL}api/token-access`;
const URL_UPLOAD = `${ROOT_URL}api/upload`;

// const URL_UPLOAD = `http://10.8.26.129:8000/api/upload`
const URL_PROFILE = `${ROOT_URL}api/auth/me`;
const URL_EXIST = `${ROOT_URL}api/user/exists`;
const URL_BODY_MODEL_LIST = `${ROOT_URL}api/study-list`; //screenings
const URL_BODY_MODEL_PART = `${ROOT_URL}api/body-part-list`; //body-model
const URL_STUDY_DELETE = `${ROOT_URL}api/study/delete?study_id=`; //screenings del
const URL_STUDY_UPDATE = `${ROOT_URL}api/study/update`; //screenings del
const URL_STUDY_DOWNLOAD = `${ROOT_URL}api/study/download?study_id=`; //download study
const URL_USER_DELETE = `${ROOT_URL}api/user/delete`;
const URL_ARCHIVE_STUDY = `${ROOT_URL}api/archive`;
const URL_FORGOT_PASSWORD = `${ROOT_URL}rest-auth/password/reset/`;
const URL_CONFIRM_PASSWORD = `${ROOT_URL}rest-auth/password/reset/confirm/`;
const URL_REFRESH_TOKEN = `${ROOT_URL}api/token-refresh`;

export const api = {
  source: CancelToken.source(),

  async fetchRegistration(user) {
    return await axios.post(URL_AUTH, user);
  },

  async fetchExistRegistration(user) {
    return await axios.post(URL_EXIST, user);
  },

  async fetchSignIn(user) {
    return await axios.post(URL_SIGNIN, user);
  },

  async fetchSocial(user, url) {
    return await axios.post(`${ROOT_URL}rest-auth/${url}/`, user);
  },

  async fetchUploadFiles(data) {
    // axios.defaults.headers.common["Authorization"] = `JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiODc2OWFjZjQtNjU1NC00Mzk4LTlhMTgtZmZjYjc4Zjc4MDUyIiwidXNlcm5hbWUiOiJtZWdvbG9tYW5AdWtyLm5ldCIsImV4cCI6MTU2NDQ5MjI0NiwiZW1haWwiOiJtZWdvbG9tYW5AdWtyLm5ldCIsIm9yaWdfaWF0IjoxNTYzODg3NDQ2fQ.oXty404LtCsskjAIzjiDmQMkpPLfuKgoWApmVqozHcY`
    return await axios.post(URL_UPLOAD, data, {
      cancelToken: this.source.token,
    });
  },

  async fetchProfile() {
    return await axios.get(URL_PROFILE);
  },

  async fetchProfileEdit(data) {
    return await axios.put(URL_PROFILE, data);
  },

  async fetchBodyModel() {
    return await axios.get(URL_BODY_MODEL_PART);
  },

  async fetchScreenings() {
    return await axios.get(URL_BODY_MODEL_LIST);
  },

  async fetchDeleteStudy(id) {
    return await axios.delete(URL_STUDY_DELETE + id);
  },

  async fetchDownloadStudy(id) {
    return await axios.get(URL_STUDY_DOWNLOAD + id);
  },

  async fetchStudyUpdate(data) {
    return await axios.put(URL_STUDY_UPDATE, data);
  },

  async fetchDeleteUser() {
    return await axios.delete(URL_USER_DELETE);
  },

  async fetchArchiveStudy(data) {
    return await axios.post(URL_ARCHIVE_STUDY, { studies: data });
  },

  fetchForgotPassword(data) {
    return axios.post(URL_FORGOT_PASSWORD, data);
  },

  fetchConfirmPassword(data) {
    return axios.post(URL_CONFIRM_PASSWORD, data);
  },

  async fetchTokenRefresh(data) {
    return axios.post(URL_REFRESH_TOKEN, { token: data });
  },

  fetchCancel() {
    this.source.cancel();
    this.source = CancelToken.source();
  },
};
