import OuterBody from './W_Outer_Body.png';
import head from './W_Head.png';
import Skeletal_Empty from './W_Skeletal_Empty.png';
import chest from './W_Chest.png';
import l_femur from './W_L_Femur.png';
import l_foot from './W_L_Foot.png';
import l_forearm from './W_L_Forearm.png';
import l_lowerleg from './W_L_Lower_Leg.png';
import l_shoulder from './W_L_Shoulder.png';
import l_wrist from './W_L_Wrist.png';
import neck from './W_Neck.png';
import pelvis from './W_Pelvis.png';
import r_femur from './W_R_Femur.png';
import r_foot from './W_R_Foot.png';
import r_forearm from './W_R_Forearm.png';
import r_lowerleg from './W_R_Lower_Leg.png';
import r_shoulder from './W_R_Shoulder.png';
import r_wrist from './W_R_Wrist.png';
import spine from './W_Spine.png';

import head_glow from './glow/W_Head_Glow.png';
import chest_glow from './glow/W_Chest_Glow.png';
import l_femur_glow from './glow/W_L_Femur_Glow.png';
import l_foot_glow from './glow/W_L_Foot_Glow.png';
import l_forearm_glow from './glow/W_L_Forearm_Glow.png';
import l_lowerleg_glow from './glow/W_L_Lower_Leg_Glow.png';
import l_shoulder_glow from './glow/W_L_Shoulder_Glow.png';
import l_wrist_glow from './glow/W_L_Wrist_Glow.png';
import neck_glow from './glow/W_Neck_Glow.png';
import pelvis_glow from './glow/W_Pelvis_Glow.png';
import r_femur_glow from './glow/W_R_Femur_Glow.png';
import r_foot_glow from './glow/W_R_Foot_Glow.png';
import r_forearm_glow from './glow/W_R_Forearm_Glow.png';
import r_lowerleg_glow from './glow/W_R_Lower_Leg_Glow.png';
import r_shoulder_glow from './glow/W_R_Shoulder_Glow.png';
import r_wrist_glow from './glow/W_R_Wrist_Glow.png';
import spine_glow from './glow/W_Spine_Glow.png';

export const AvatarImagesWoman = {
  OuterBody,
  head,
  Skeletal_Empty,
  chest,
  l_femur,
  l_foot,
  l_forearm,
  l_lowerleg,
  l_shoulder,
  l_wrist,
  neck,
  pelvis,
  r_femur,
  r_foot,
  r_forearm,
  r_lowerleg,
  r_shoulder,
  r_wrist,
  spine,
};

export const AvatarImagesGlowWoman = {
  head_glow,
  chest_glow,
  l_femur_glow,
  l_foot_glow,
  l_forearm_glow,
  l_lowerleg_glow,
  l_shoulder_glow,
  l_wrist_glow,
  neck_glow,
  pelvis_glow,
  r_femur_glow,
  r_foot_glow,
  r_forearm_glow,
  r_lowerleg_glow,
  r_shoulder_glow,
  r_wrist_glow,
  spine_glow,
};
