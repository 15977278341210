import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Logo from './logo';
import Logout from '../Logout/';
import Spinner from '../spinner/';

import { profileAction } from '../../redux/actions/profileAction';
import { screeningsAction } from '../../redux/actions/screeningsAction';
import { bodyModelAction } from '../../redux/actions/bodyModelAction';
import { ROUT } from '../../constants/';
import { slide as Menu } from 'react-burger-menu';

import './index.scss';


const NavMenu = () => {
  return (
    <div className="header_nav">
      <NavLink
        activeClassName="header__item--selected"
        to={ROUT.bodyModel}
        className="header__item"
      >
        Body model
      </NavLink>
      <NavLink
        activeClassName="header__item--selected"
        to={ROUT.screenings}
        className="header__item"
      >
        Screenings
      </NavLink>
      <NavLink
        activeClassName="header__item--selected"
        to={ROUT.profile}
        className="header__item"
      >
        Profile
      </NavLink>
      <Logout className="header__item header__item--logout" />
    </div>
  );
};

class HeaderAdmin extends Component {

  constructor (props) {
    super(props)
    this.state = {
      menuOpen: false
    }
  }

  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})
  }


  closeMenu () {
    this.setState({menuOpen: false})
  }

  toggleMenu () {
    this.setState(state => ({menuOpen: !state.menuOpen}))
  }

  fetchActions = async () => {
    const { profileAction, screeningsAction, bodyModelAction } = this.props;
    await profileAction();
    screeningsAction();
    bodyModelAction();
  };

  componentDidMount() {
    this.fetchActions();
  }

  render() {
    const { loader } = this.props;

    const windowWidth = window.screen.width;
    return (
      <header className="header header--admin">
        <div className="container flex-row">
          {windowWidth < 1380 ?  <Menu className="header__nav" isOpen={this.state.menuOpen} onStateChange={(state) => this.handleStateChange(state)} >
            <NavLink
                activeClassName="header__item--selected"
                to={ROUT.bodyModel}
                className="header__item"
                onClick={() => this.closeMenu()}
            >
              Body model
            </NavLink>
            <NavLink
                activeClassName="header__item--selected"
                to={ROUT.screenings}
                className="header__item"
                onClick={() => this.closeMenu()}
            >
              Screenings
            </NavLink>
            <NavLink
                activeClassName="header__item--selected"
                to={ROUT.profile}
                className="header__item"
                onClick={() => this.closeMenu()}
            >
              Profile
            </NavLink>
            <Logout className="header__item header__item--logout" />
          </Menu>
              :
              <NavMenu />}


          <div className="header__logoWrapper ">
            <Logo className="header__logo " />
          </div>

          <div className="header__links header__admin"/>
        </div>
        <Spinner isFetching={loader} />
      </header>
    );
  }
}

const mapStateToProps = state => {
  return {
    loader: state.profile.loader,
    screeningsList: state.screenings.dataList,
    bodyModelData: state.bodyModel.body,
  };
};

const mapDispatchToProps = {
  profileAction,
  screeningsAction,
  bodyModelAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderAdmin);
