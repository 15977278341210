import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/img/logo.svg';

const Logo = ({ className }) => {
  return (
    <Link to="/">
      <img className={className} src={logo} alt="logo" />
    </Link>
  );
};

export default Logo;
