import React from 'react';
import { connect } from 'react-redux';

import ProgressBackup from '../../../../components/progressBackup/';
import Tooltip from '../Tooltip/';

import { AvatarImages, AvatarImagesGlow } from '../../../../assets/img/avatar/';
import {
  AvatarImagesWoman,
  AvatarImagesGlowWoman,
} from '../../../../assets/img/woman/';

import './index.scss';

const layersGender = (item, layersImages, layersImagesGlow) => {
  return (
    <React.Fragment key={item.id}>
      <Tooltip ItemLayers={item} />
      <img
        className="Avatar__item "
        src={layersImagesGlow[item.name_glow]}
        alt={item.name}
      />
      <img
        className="Avatar__item Avatar__item--active"
        src={layersImages[item.name]}
        alt={item.name}
      />
    </React.Fragment>
  );
};

const layersRender = (layers, UserGender) => {
  return UserGender === 2
    ? layers.body.map(item =>
        AvatarImagesWoman[item.name]
          ? layersGender(item, AvatarImagesWoman, AvatarImagesGlowWoman)
          : null
      )
    : layers.body.map(item =>
        AvatarImages[item.name]
          ? layersGender(item, AvatarImages, AvatarImagesGlow)
          : null
      );
};

const Avatar = ({ bodyModelData, gender, selectBodyNavSelect }) => {
  const UserGender = gender && gender.gender;

  const displayGenderBody =
    UserGender && UserGender === 2
      ? AvatarImagesWoman.OuterBody
      : AvatarImages.OuterBody;
  const displayGenderSkeletal =
    UserGender && UserGender === 2
      ? AvatarImagesWoman.Skeletal_Empty
      : AvatarImages.Skeletal_Empty;
  return (
    <React.Fragment>
      <div className="BodyModel__progress">
        <ProgressBackup />
      </div>
      <div className="Avatar">
        <img
          className="Avatar__item Avatar__item--base"
          src={displayGenderBody}
          alt="Human ADAM"
        />
        {selectBodyNavSelect.name === 'SKELETAL' ? (
          <React.Fragment>
            <img
              className="Avatar__item Avatar__item--active"
              src={displayGenderSkeletal}
              alt="Human ADAM"
            />
            {layersRender(bodyModelData, UserGender)}
          </React.Fragment>
        ) : null}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    selectBodyNavSelect: state.selectBodyNav.select,
    bodyModelData: state.bodyModel,
    gender: state.profile.user,
  };
};

export default connect(
  mapStateToProps,
  null
)(Avatar);
