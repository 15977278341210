import React, { Component } from 'react';
import PopupOver from '../PopupOver/';
import { PrivacyBody, PrivacyHeader } from './../Privacy';
import { AboutHeader } from './../AboutProject';

import './index.scss';

class Footer extends Component {
  state = {
    popupPolicy: false,
    popupAbout: false,
  };

  onClosePopup = () => {
    this.setState({
      popupPolicy: false,
      popupAbout: false,
    });
  };

  onPopupPolicy = () => {
    this.setState({
      popupPolicy: !this.state.popupPolicy,
    });
  };

  onPopupAbout = () => {
    this.setState({
      popupAbout: !this.state.popupAbout,
    });
  };

  render() {
    const { popupPolicy, popupAbout } = this.state;

    return (
      <footer className="footer">
        <span className="footer__link" onClick={this.onPopupPolicy}>
          Privacy Policy
        </span>
        <span className="footer__link" onClick={this.onPopupAbout}>
          About project
        </span>
        <span className="footer__link footer__link--copirate">ADAM-2019©</span>
        {popupPolicy && (
          <PopupOver title={<PrivacyHeader />} onClose={this.onClosePopup}>
            <div className="popup-content">
              <PrivacyBody />
            </div>
          </PopupOver>
        )}
        {popupAbout && (
          <PopupOver title={<AboutHeader />} onClose={this.onClosePopup}>
            <div className="popup-content">
              A.D.A.M. – Advanced Development of Additive Manufacturing <br />
              <br />
              ADAM is new innovative additive manufacturing project , which aims
              to challenge common understanding of bones and their fragility.
              The core of our approach is the combination of new materials for
              3D printing of bones. <br />
              We propose to create Digital Body Atlas with individual scans of
              bones, which could be printed on demand when needed.
              <br />
              <br />
              How to get these scans? Each time people use computer tomography,
              they get 3D scans of bones, which they usually just keep archived.
              ADAM proposes to upload all this information to online platform to
              be sure that in urgent situation one get 3D print of bones <br />
            </div>
          </PopupOver>
        )}
      </footer>
    );
  }
}

export default Footer;
