import { FETCH_ERROR_ACTION_POPUP, FETCH_ERROR_CLEAR } from '../actionsTypes';

const initialState = {
  popup: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ERROR_ACTION_POPUP:
      return {
        ...state,
        popup: true,
      };
    case FETCH_ERROR_CLEAR:
      return {
        ...state,
        popup: false,
      };
    default:
      return state;
  }
};
