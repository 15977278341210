import React from 'react';

export const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="28"
    viewBox="0 0 30 28"
  >
    <g fill="none" fillRule="evenodd" stroke="#FFF">
      <path d="M1 0l27.738 27.738M28.738 0L1 27.738" />
    </g>
  </svg>
);
